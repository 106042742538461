// React
import {useContext} from 'react'
import {If, Then} from 'react-if'

// Material UI
import {Box} from '@mui/material'

// Font Awesome
import {faEdit} from '@fortawesome/pro-regular-svg-icons'

// Context
import {SelectedInsightContext} from '../../../../context/SelectedInsightContext'
import {ValidationContext} from '../../../../context/ValidationContext'

// Components
import {DiagnosisSelectionContent} from './DiagnosisSelectionContent'
import {MoxeIcon} from '@moxe/component-library'
import {MoxeButton} from '../../../reusable/MoxeButton'
import {RadioOption} from '../../../reusable/RadioOption'

// Types
import {ActionEnum} from '../../../../types/state/ActionEnum'

// CSS
import {colors} from '../../../../css/colors'
import {fontWeights} from '../../../../css/fontWeights'
import {fontSizes} from '../../../../css/fontSizes'

// Utils
import {formatDiagnosisForDisplay} from '../../../../utils/formatDiagnosisForDisplay'
import {defaultInsightValidation} from '../../../../utils/validation'

export const DiagnosisSelectionOption = () => {
  const {selectedInsight, updateInsight} = useContext(SelectedInsightContext)
  const {shouldShowValidationStyling} = useContext(ValidationContext)

  const clickEdit = () => {
    let selectedInsightCopy = {...selectedInsight}
    selectedInsightCopy.isEditMode = !selectedInsight.isEditMode
    updateInsight(selectedInsightCopy)
  }

  const clickDiagnosisRadio = () => {
    let selectedInsightCopy = {...selectedInsight}
    selectedInsightCopy.selectedDiagnosis = selectedInsight.recommendedDiagnosis
    selectedInsightCopy.actionInput = ActionEnum.Accepted
    selectedInsightCopy.reasonInput = null
    selectedInsightCopy.noteInput = null
    selectedInsightCopy.isEditMode = false
    selectedInsightCopy.validation = defaultInsightValidation()
    updateInsight(selectedInsightCopy)
  }

  const editButtonText = selectedInsight.isEditMode ? (
    'Cancel editing'
  ) : (
    <Box>
      <Box
        id={'editDiagnosisButton' + selectedInsight.id}
        data-testid={'editDiagnosisButton' + selectedInsight.id}
        sx={{display: 'inline-flex', marginRight: '8px'}}
      >
        Edit Dx
      </Box>
      <Box
        display='inline-flex'
        id={'editDiagnosisIconContainer' + selectedInsight.id}
        data-testid={'editDiagnosisIconContainer' + selectedInsight.id}
      >
        <MoxeIcon
          icon={faEdit}
          color={colors.gray[900]}
          fontSize={fontSizes.sm}
        />
      </Box>
    </Box>
  )

  const getDiagnosisSelectContainerStyle = () => {
    const isValid =
      selectedInsight.validation.isActionValid &&
      selectedInsight.validation.isSelectedDiagnosisValid
    let borderColor = colors.red[300]
    if (isValid || !shouldShowValidationStyling || selectedInsight.isEditMode) {
      if (selectedInsight.actionInput === ActionEnum.Accepted) {
        borderColor = colors.gray[400]
      } else {
        borderColor = colors.gray[300]
      }
    }
    return {
      borderRadius: '2px',
      marginBottom: '4px',
      backgroundColor: selectedInsight.isEditMode
        ? colors.gray[50]
        : colors.gray[0],
      border: `1px solid ${borderColor}`
    }
  }

  const diagnosisLabelButtonContainerStyle = {
    height: '24px',
    padding: '7px 12px 7px 0px'
  }

  const noDiagnosisStyle = {
    fontWeight: fontWeights.md,
    height: '28px',
    lineHeight: '28px',
    padding: '5px 12px'
  }

  // if there is a selected diagnosis, use that.
  // otherwise, if there is a recommended diagnosis, use that.
  // otherwise, there isn't a diagnosis to display
  let diagnosisLabel
  let diagnosisToDisplay = selectedInsight.selectedDiagnosis
    ? selectedInsight.selectedDiagnosis
    : selectedInsight.recommendedDiagnosis
    ? selectedInsight.recommendedDiagnosis
    : null
  if (diagnosisToDisplay)
    diagnosisLabel = (
      <RadioOption
        optionId={'InsightDiagnosis' + selectedInsight.id}
        text={formatDiagnosisForDisplay(diagnosisToDisplay)}
        onChange={clickDiagnosisRadio}
        isSelected={selectedInsight.actionInput === ActionEnum.Accepted}
        shouldRemoveIcons={true}
        customStyle={{flex: 1, fontWeight: fontWeights.md}}
      />
    )
  else
    diagnosisLabel = (
      <Box
        id='noDiagnosisSelectedText'
        data-testid='noDiagnosisSelectedText'
        sx={noDiagnosisStyle}
      >
        No diagnosis yet.
      </Box>
    )

  const diagnosisSelectionOptionId =
    'diagnosisSelectionOption' + selectedInsight.id

  return (
    <Box
      id={diagnosisSelectionOptionId}
      data-testid={diagnosisSelectionOptionId}
      sx={getDiagnosisSelectContainerStyle()}
    >
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        {diagnosisLabel}
        <Box sx={diagnosisLabelButtonContainerStyle}>
          <MoxeButton
            buttonId={'EditDiagnosis' + selectedInsight.id}
            text={editButtonText}
            moxeVariant='secondary'
            height='24px'
            onClick={clickEdit}
          />
        </Box>
      </Box>
      <If condition={selectedInsight.isEditMode}>
        <Then>
          <DiagnosisSelectionContent />
        </Then>
      </If>
    </Box>
  )
}
