import {Box, Skeleton} from '@mui/material'
import {InsightSupportingEvidenceHeader} from '../reusable/InsightSupportingEvidenceHeader'

export const InsightSupportingEvidenceLoading = () => {
  const evidenceItemSkeletonStyle = {
    height: '87px',
    marginBottom: '8px',
    borderRadius: '4px',
    backgroundColor: '#F0F0F0'
  }

  return (
    <Box
      id='insightSupportingEvidenceLoading'
      data-testid='insightSupportingEvidenceLoading'
    >
      <InsightSupportingEvidenceHeader isHeaderForLoadingScreen={true} />
      {[...Array(2)].map((_, index) => (
        <Skeleton
          animation='wave'
          variant='rectangular'
          sx={evidenceItemSkeletonStyle}
          key={'evidenceItemLoading' + index}
        />
      ))}
    </Box>
  )
}
