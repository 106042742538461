// Material UI
import {Box} from '@mui/material'

// CSS
import {fontWeights} from '../../css/fontWeights'
import {colors} from '../../css/colors'

export const CommonLabel = (props: any) => {
  const {text} = props

  const textStyle = {
    display: 'inline-flex',
    fontWeight: fontWeights.lg,
    marginBottom: '8px',
    marginRight: '2px',
    color: colors.gray[1000]
  }

  return (
    <Box id={`label${text}`} data-testid={`label${text}`} sx={textStyle}>
      {text}
    </Box>
  )
}
