// React
import {useContext} from 'react'

// Material UI
import {Accordion, AccordionDetails, Container, RadioGroup} from '@mui/material'

// Components
import {RequiredLabel} from '../../../reusable/RequiredLabel'
import {RadioOption} from '../../../reusable/RadioOption'
import {CommonLabel} from '../../../reusable/CommonLabel'
import {MoxeTextField} from '../../../reusable/MoxeTextField'

// Context
import {SelectedInsightContext} from '../../../../context/SelectedInsightContext'
import {ValidationContext} from '../../../../context/ValidationContext'

// Types
import {ActionEnum} from '../../../../types/state/ActionEnum'
import {QualityReason} from '../../../../types/state/QualityReason'

// CSS
import {colors} from '../../../../css/colors'
import {radioOptionStyle} from '../../../../css/styles/radioOptionStyle'

// Utils
import {defaultInsightValidation} from '../../../../utils/validation'

export const ActionForm = () => {
  const {selectedInsight, updateInsight} = useContext(SelectedInsightContext)
  const {shouldShowValidationStyling} = useContext(ValidationContext)

  const updateSelectedInput = (
    action: ActionEnum,
    reason: QualityReason | null
  ) => {
    let selectedInsightCopy = {...selectedInsight}
    selectedInsightCopy.actionInput = action
    selectedInsightCopy.noteInput = null
    selectedInsightCopy.reasonInput = reason
    selectedInsightCopy.validation = defaultInsightValidation()
    updateInsight(selectedInsightCopy)
  }

  const updateSelectedQualityAction = (qualityAction: string) => {
    if (qualityAction === 'Address') {
      updateSelectedInput(ActionEnum.Accepted, null)
    } else if (qualityAction === 'Patient refused') {
      updateSelectedInput(ActionEnum.Rejected, 'Patient refused')
    } else if (qualityAction === 'Reject - See comments') {
      updateSelectedInput(ActionEnum.Rejected, 'Reject - See comments')
    }
  }

  const handleNoteOnChange = (newNote: string) => {
    let selectedInsightCopy = {...selectedInsight}
    selectedInsightCopy.noteInput = newNote
    updateInsight(selectedInsightCopy)
  }

  const getAccordionStyle = (isOptionSelected: boolean) => {
    return {
      width: '100%',
      marginBottom: '4px',
      '&:before': {
        display: 'none'
      },
      boxShadow: 'none',
      border: `1px solid ${
        selectedInsight.validation.isActionValid || !shouldShowValidationStyling
          ? colors.gray[300]
          : colors.red[300]
      }`,
      '&.MuiAccordion-root': {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        borderRadius: '2px'
      },
      backgroundColor: isOptionSelected ? colors.gray[50] : colors.gray[0]
    }
  }

  const qualityActionToReason: any = {
    Address: '',
    'Patient refused': 'PatientRefused',
    'Reject - See comments': 'RejectSeeComments'
  }

  return (
    <Container id='actionForm' data-testid='actionForm' disableGutters>
      <RequiredLabel text='Action' />

      <RadioGroup>
        {Object.keys(qualityActionToReason).map((qualityAction: string) => {
          const actionOptionId = 'Action' + selectedInsight.id + qualityAction
          const actionOptionAccordionDetailsId =
            'actionAccordionDetails' + selectedInsight.id + qualityAction
          const actionLabel = qualityAction
          const isActionOptionSelected: boolean =
            (selectedInsight.actionInput === ActionEnum.Accepted &&
              qualityAction === 'Address') ||
            (selectedInsight.actionInput === ActionEnum.Rejected &&
              selectedInsight.reasonInput === qualityAction)
          return (
            <Accordion
              id={actionOptionId}
              data-testid={actionOptionId}
              disableGutters
              sx={getAccordionStyle(isActionOptionSelected)}
              expanded={isActionOptionSelected}
              key={actionOptionId}
            >
              <RadioOption
                optionId={actionOptionId}
                text={actionLabel}
                onChange={() => updateSelectedQualityAction(qualityAction)}
                isSelected={isActionOptionSelected}
                customStyle={radioOptionStyle}
              />

              <AccordionDetails
                id={actionOptionAccordionDetailsId}
                data-testid={actionOptionAccordionDetailsId}
                sx={{padding: '4px 12px 12px 12px'}}
              >
                <CommonLabel text='What is the status of this measure?' />
                <MoxeTextField
                  textFieldId={'Action' + qualityAction + selectedInsight.id}
                  text={selectedInsight.noteInput}
                  placeholderText='What steps will be taken to close this quality gap?'
                  onChange={(event) => handleNoteOnChange(event.target.value)}
                />
              </AccordionDetails>
            </Accordion>
          )
        })}
      </RadioGroup>
    </Container>
  )
}
