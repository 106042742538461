// Material UI
import {Box} from '@mui/material'

// CSS
import {colors} from '../../../css/colors'

export const DiagnosisWritebackErrors = (props: {
  dxWritebackErrors: string[]
}) => {
  const {dxWritebackErrors} = props

  const diagnosisErrorsContainerStyle = {
    padding: '8px 12px',
    background: colors.gray[100],
    border: `1px solid ${colors.gray[200]}`,
    borderRadius: '4px',
    textAlign: 'left'
  }

  const individualDiagnosisErrorStyle = {
    display: 'inline-block',
    marginBottom: '4px',
    color: colors.gray[800]
  }

  return (
    <Box>
      <Box sx={{marginBottom: '16px'}}>
        Something went wrong, and we weren’t able to write back the following
        codes to the EMR. Please manually add the following codes in the EMR.
      </Box>
      <Box sx={diagnosisErrorsContainerStyle}>
        {dxWritebackErrors.map((error) => (
          <Box key={error} sx={individualDiagnosisErrorStyle}>
            {error.replace(
              'Failed to write the icd10 back to the visit diagnoses in the EMR for hcc: ',
              ''
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
