// React
import {useContext} from 'react'

// Material UI
import {Grid, Box} from '@mui/material'

// Font Awesome
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {faArrowDown, faArrowUp} from '@fortawesome/pro-regular-svg-icons'

// Context
import {AppContext} from '../../../context/AppContext'
import {SelectedInsightContext} from '../../../context/SelectedInsightContext'

// Components
import {MoxeIcon} from '@moxe/component-library'

// Types
import {InsightStatusEnum} from '../../../types/state/InsightStatusEnum'

// CSS
import {colors} from '../../../css/colors'
import {fontSizes} from '../../../css/fontSizes'
import {fontWeights} from '../../../css/fontWeights'

// Utils
import {isInsightIncomplete} from '../../../utils/isInsightIncomplete'

export const InsightFormHeader = () => {
  // Using this to get the total number of insights in the app,
  // but is there a more performant way to do this?
  const {insights} = useContext(AppContext)
  const {selectedInsight, updateSelectedInsight, updateInsight} = useContext(
    SelectedInsightContext
  )

  const numberOfInsights = insights.length
  const selectedInsightIndex = insights.indexOf(selectedInsight)
  const formGridStyle = {
    paddingBottom: '21px',
    marginBottom: '16px',
    borderBottom: `1px solid ${colors.gray[300]}`
  }

  const categoryLabelStyle = {
    color: colors.gray[600],
    fontSize: fontSizes.sm,
    fontWeight: fontWeights.md,
    marginBottom: '8px'
  }

  const insightNameStyle = {
    fontWeight: fontWeights.xl,
    fontSize: fontSizes.lg
  }

  const insightNumberLabelStyle = {
    display: 'inline-flex',
    backgroundColor: colors.gray[50],
    color: colors.gray[900],
    padding: '4px 12px',
    borderRadius: '3px',
    float: 'right',
    fontWeight: fontWeights.md,
    height: '16px',
    lineHeight: '16px'
  }
  const nextStyle = {
    ...insightNumberLabelStyle,
    backgroundColor: colors.gray[0],
    border: 'solid 1px',
    borderColor: colors.gray[400],
    borderRadius: '0px 3px 3px 0px',
    '&:hover': {
      cursor: 'pointer'
    },
    '& svg': {
      paddingTop: '2px'
    }
  }
  const prevStyle = {
    ...nextStyle,
    borderColor: colors.gray[400],
    borderRadius: '3px 0px 0px 3px',
    borderRight: 'none'
  }

  const selectPreviousInsight = () => {
    if (selectedInsightIndex !== 0) {
      if (isInsightIncomplete(selectedInsight)) {
        let selectedInsightCopy = {...selectedInsight}
        selectedInsightCopy.status = InsightStatusEnum.Incomplete
        updateInsight(selectedInsightCopy)
      }
      const prevInsight = insights[selectedInsightIndex - 1]
      updateSelectedInsight(prevInsight)
    }
  }

  const selectNextInsight = () => {
    if (selectedInsightIndex !== numberOfInsights - 1) {
      if (isInsightIncomplete(selectedInsight)) {
        let selectedInsightCopy = {...selectedInsight}
        selectedInsightCopy.status = InsightStatusEnum.Incomplete
        updateInsight(selectedInsightCopy)
      }
      const nextInsight = insights[selectedInsightIndex + 1]
      updateSelectedInsight(nextInsight)
    }
  }

  return (
    <Grid container spacing={0} sx={formGridStyle}>
      <Grid item xs={9}>
        <Box
          id='selectedInsightCategoryText'
          data-testid='selectedInsightCategoryText'
          sx={categoryLabelStyle}
        >
          {selectedInsight.category.toUpperCase()}
        </Box>
        <Box
          id='selectedInsightNameText'
          data-testid='selectedInsightNameText'
          sx={insightNameStyle}
        >
          {selectedInsight.name}
        </Box>
      </Grid>
      <Grid item xs={1.5}>
        <Box
          id='insightOrderLabel'
          data-testid='insightOrderLabel'
          sx={insightNumberLabelStyle}
        >
          {selectedInsightIndex + 1 + ' of ' + insights.length}
        </Box>
      </Grid>
      <Grid item xs={1.5}>
        <Box
          id='nextInsight'
          data-testid='nextInsight'
          sx={nextStyle}
          onClick={selectNextInsight}
        >
          <MoxeIcon
            icon={faArrowDown as IconProp}
            color={colors.gray[900]}
            fontSize={fontSizes.sm}
          />
        </Box>
        <Box
          id='previousInsight'
          data-testid='previousInsight'
          sx={prevStyle}
          onClick={selectPreviousInsight}
        >
          <MoxeIcon
            icon={faArrowUp as IconProp}
            color={colors.gray[900]}
            fontSize={fontSizes.sm}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
