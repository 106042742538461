// React
import {useContext, useEffect, useState} from 'react'

// Material UI
import {Box} from '@mui/system'
import {Collapse, ListItemButton} from '@mui/material'

// Font Awesome
import {faChevronUp, faChevronDown} from '@fortawesome/pro-regular-svg-icons'

// Components
import {EncounterDetailsLoading} from '../../loading/EncounterDetailsLoading'
import {EncounterDetailsContent} from './EncounterDetailsContent'
import {EncounterDetailsError} from './EncounterDetailsError'
import {MoxeIcon} from '@moxe/component-library'

// Context
import {AppContext} from '../../../context/AppContext'
import {EncounterDetailsContext} from '../../../context/EncounterDetailsContext'

// Constants
import {getConvergenceApiHost} from '../../../constants/api/apiHosts'
import {getEncounterDetailsEndpoint} from '../../../constants/api/apiEndpoints'
import {getOptionsWithAuthorizationHeader} from '../../../constants/api/apiOptions'

// Types
import {EncounterDetailsResponse} from '../../../types/api/responses/EncounterDetailsResponse'
import {PatientDetails} from '../../../types/state/PatientDetails'

// Utils
import {moxeFetch} from '../../../utils/api/moxeFetch'

// CSS
import {colors} from '@moxe/design-tokens'
import {fontSizes} from '../../../css/fontSizes'
import {leftPaneDropdownHeaderStyle} from '../../../css/styles/leftPaneDropdownHeaderStyle'
import {leftPaneDropdownTitleStyle} from '../../../css/styles/leftPaneDropdownTitleStyle'

export const EncounterDetails = () => {
  const {token, isLoadingConfiguration} = useContext(AppContext)
  const {setCommentToProvider} = useContext(EncounterDetailsContext)

  const [isLoadingEncounterDetails, setIsLoadingEncounterDetails] =
    useState<boolean>(true)
  const [isEncounterDetailError, setIsEncounterDetailError] =
    useState<boolean>(false)
  const [
    isPatientDetailsDropdownExpanded,
    setIsPatientDetailsDropdownExpanded
  ] = useState<boolean>(true)
  const [patientDetails, setPatientDetails] = useState<PatientDetails>({
    patientName: null,
    patientDOB: null,
    patientMRN: null
  })

  useEffect(() => {
    if (token !== '') {
      moxeFetch<EncounterDetailsResponse>(
        getConvergenceApiHost() + getEncounterDetailsEndpoint(),
        getOptionsWithAuthorizationHeader('get', token)
      )
        .then((result) => {
          setPatientDetails({
            patientName: result.patientName,
            patientDOB: result.patientDOB,
            patientMRN: result.patientMRN
          })
          setCommentToProvider(result.commentToProvider)
        })
        .catch(() => {
          setIsEncounterDetailError(true)
        })
        .finally(() => {
          setIsLoadingEncounterDetails(false)
        })
    }
  }, [token])

  const encounterDetailsContainerStyle = {
    padding: '16px 16px 4px 16px',
    borderRadius: '4px',
    border: `1px solid ${colors.gray[300]}`,
    background: colors.gray[100]
  }

  const encounterDetailsId = 'encounterDetails'
  const dropdownId = 'buttonPatientDetailsDropdown'
  const encounterDetailsContentContainerId = 'encounterDetailsContentContainer'

  let dropdownIcon
  if (isPatientDetailsDropdownExpanded) {
    dropdownIcon = faChevronDown
  } else {
    dropdownIcon = faChevronUp
  }

  let encounterDetailsContent
  if (isLoadingEncounterDetails || isLoadingConfiguration) {
    encounterDetailsContent = <EncounterDetailsLoading />
  } else if (isEncounterDetailError) {
    encounterDetailsContent = <EncounterDetailsError />
  } else {
    encounterDetailsContent = (
      <EncounterDetailsContent
        patientName={patientDetails.patientName}
        patientDOB={patientDetails.patientDOB}
        patientMRN={patientDetails.patientMRN}
      />
    )
  }

  return (
    <Box
      id={encounterDetailsId}
      data-testid={encounterDetailsId}
      sx={{marginBottom: '16px'}}
    >
      <ListItemButton
        id={dropdownId}
        data-testid={dropdownId}
        disableGutters
        sx={leftPaneDropdownHeaderStyle}
        onClick={() =>
          setIsPatientDetailsDropdownExpanded(!isPatientDetailsDropdownExpanded)
        }
      >
        <MoxeIcon
          icon={dropdownIcon}
          color={colors.gray[800]}
          fontSize={fontSizes.sm}
        />
        <Box sx={leftPaneDropdownTitleStyle}>Patient Details</Box>
      </ListItemButton>

      <Collapse in={isPatientDetailsDropdownExpanded}>
        <Box
          id={encounterDetailsContentContainerId}
          data-testid={encounterDetailsContentContainerId}
          sx={encounterDetailsContainerStyle}
        >
          {encounterDetailsContent}
        </Box>
      </Collapse>
    </Box>
  )
}
