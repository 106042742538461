// Standard stop words used by Lucene/Elasticsearch
const stopWords = [
  'a',
  'an',
  'and',
  'are',
  'as',
  'at',
  'be',
  'but',
  'by',
  'for',
  'if',
  'in',
  'into',
  'is',
  'it',
  'no',
  'not',
  'of',
  'on',
  'or',
  'such',
  'that',
  'the',
  'their',
  'then',
  'there',
  'these',
  'they',
  'this',
  'to',
  'was',
  'will',
  'with',
  '',
  ' ',
  ';'
]
const regex = new RegExp(/ |['-*,-.<>[\]^{\\}]/)
export const tokenize: any = (
  inputString: string,
  toLower: boolean = true,
  removeStopWords: boolean = true
) => {
  let tokens = (toLower ? inputString.toLowerCase() : inputString).split(regex)
  tokens = removeStopWords
    ? tokens.filter((token) => stopWords.indexOf(token) < 0)
    : tokens
  return tokens
}

export const moxeFilter: any = (
  filterText: string,
  valuesToFilter: {}[],
  toLower: boolean = true,
  removeStopWords: boolean = false
) => {
  let tokens: string[] = tokenize(filterText, toLower, removeStopWords)
  let filtered: {}[] = valuesToFilter.filter((vtf) => {
    let properties = Object.keys(vtf)
    let tokensFound = 0
    tokens.forEach((token) => {
      let tokenFound = false
      properties.forEach((prop) => {
        if (!tokenFound) {
          let value = ({...vtf}[prop] + '').toLowerCase()
          if (value.includes(token)) {
            tokenFound = true
          }
        }
      })
      if (tokenFound) {
        tokensFound++
      }
    })
    return tokensFound === tokens.length
  })
  return filtered
}
