// Material UI
import {Box} from '@mui/material'

// Components
import {CommonLabel} from './CommonLabel'
import {RequiredAsterisk} from '../reusable/RequiredAsterisk'

export const RequiredLabel = (props: any) => {
  const {text} = props

  return (
    <Box>
      <CommonLabel text={text} />
      <RequiredAsterisk data-testid={`requiredAsterisk${text}`} />
    </Box>
  )
}
