// React
import {useContext} from 'react'

// Material UI
import {Box} from '@mui/material'

// Components
import {AcceptedStatusSectionContent} from './AcceptedStatusSectionContent'
import {RejectedStatusSectionContent} from './RejectedStatusSectionContent'
import {IncompleteStatusSectionContent} from './IncompleteStatusSectionContent'
import {ReadyForReviewStatusSectionContent} from './ReadyForReviewStatusSectionContent'
import {UnsavedStatusSectionContent} from './UnsavedStatusSectionContent'

// Context
import {StatusSectionContext} from '../../../context/StatusSectionContext'

// Types
import {StatusGroupValueToSummaryDisplayNameEnum} from '../../../types/state/StatusGroupValueToSummaryDisplayNameEnum'
import {InsightSummaryDisplayNameToDescriptionEnum} from '../../../types/state/InsightSummaryDisplayNameToDescriptionEnum'

// CSS
import {fontWeights} from '../../../css/fontWeights'
import {colors} from '../../../css/colors'
import {
  insightContainerStyle,
  insightHeaderStyle,
  insightNameStyle
} from '../../../css/styles/summaryInsightStyle'

export const StatusSection = () => {
  const {statusGroup} = useContext(StatusSectionContext)

  const statusSectionStyle = {
    paddingTop: '8px',
    borderTop: `1px solid ${colors.gray[100]}`
  }

  const statusSectionHeaderStyle = {
    marginBottom: '8px',
    padding: '12px',
    borderRadius: '8px',
    border: '1px solid transparent',
    background: `linear-gradient(90deg, ${statusGroup.colorGroup[50]} 0%, ${colors.gray[50]} 100%) padding-box, linear-gradient(to right, ${statusGroup.colorGroup[200]} 0%, ${colors.gray[0]} 100%) border-box`
  }

  const countStyle = {
    display: 'inline-flex',
    justifyContent: 'center',
    margin: '3px 8px 3px 0px',
    height: '18px',
    width: '18px',
    lineHeight: '18px',
    borderRadius: '4px',
    fontWeight: fontWeights.md,
    color: colors.gray[0],
    backgroundColor: statusGroup.colorGroup[500]
  }

  const sharedStatusLabelStyle = {
    display: 'inline-flex',
    height: '24px',
    fontWeight: fontWeights.lg,
    color: colors.gray[900],
    fontSize: '16px'
  }

  const statusDescriptionStyle = {
    marginTop: '4px',
    fontWeight: fontWeights.sm
  }

  const insightAsContainerStyle = {
    ...insightContainerStyle,
    ...insightHeaderStyle,
    ...insightNameStyle
  }

  const baseId = 'statusSection'
  const statusSectionId = baseId + statusGroup.status
  const statusHeaderId = baseId + 'Header' + statusGroup.status
  const countId = baseId + 'Count' + statusGroup.status
  const labelId = baseId + 'Label' + statusGroup.status
  const descriptionId = baseId + 'Description' + statusGroup.status

  const statusSectionContent = () => {
    switch (statusGroup.status) {
      case StatusGroupValueToSummaryDisplayNameEnum.Incomplete:
        return <IncompleteStatusSectionContent />
      case StatusGroupValueToSummaryDisplayNameEnum.ReadyForReview:
        return <ReadyForReviewStatusSectionContent />
      case StatusGroupValueToSummaryDisplayNameEnum.Accepted:
        return <AcceptedStatusSectionContent />
      case StatusGroupValueToSummaryDisplayNameEnum.Rejected:
        return <RejectedStatusSectionContent />
      case StatusGroupValueToSummaryDisplayNameEnum.Unsaved:
        return <UnsavedStatusSectionContent />
      default:
        return statusGroup.insights.map((insight) => {
          const insightNameId = baseId + 'InsightName' + insight.name
          return (
            <Box
              id={insightNameId}
              data-testid={insightNameId}
              sx={insightAsContainerStyle}
              key={insight.id}
            >
              {insight.name}
            </Box>
          )
        })
    }
  }

  return (
    <Box
      id={statusSectionId}
      data-testid={statusSectionId}
      sx={statusSectionStyle}
    >
      <Box
        id={statusHeaderId}
        data-testid={statusHeaderId}
        sx={statusSectionHeaderStyle}
      >
        <Box id={countId} data-testid={countId} sx={countStyle}>
          {statusGroup.insights.length}
        </Box>
        <Box id={labelId} data-testid={labelId} sx={sharedStatusLabelStyle}>
          {statusGroup.status}
        </Box>
        {statusGroup.status !==
          StatusGroupValueToSummaryDisplayNameEnum.Accepted && (
          <Box
            id={descriptionId}
            data-testid={descriptionId}
            sx={statusDescriptionStyle}
          >
            {InsightSummaryDisplayNameToDescriptionEnum[statusGroup.status]}
          </Box>
        )}
      </Box>
      {statusSectionContent()}
    </Box>
  )
}
