import React from 'react'
import {StatusGroupValueToSummaryDisplayNameEnum} from '../types/state/StatusGroupValueToSummaryDisplayNameEnum'
import {StatusSectionContextState} from '../types/state/StatusSectionContextState'

export const StatusSectionContext =
  React.createContext<StatusSectionContextState>({
    statusGroup: {
      status: StatusGroupValueToSummaryDisplayNameEnum['Not Addressed'],
      insights: [],
      colorGroup: {}
    },
    areNotesDisplayed: false
  })
