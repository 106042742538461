// Material UI
import {Box, Button} from '@mui/material'

// Font Awesome
import {faSpinner} from '@fortawesome/pro-regular-svg-icons'

// Components
import {MoxeIcon} from './MoxeIcon'

// CSS
import {colors} from '../../css/colors'
import {fontSizes} from '../../css/fontSizes'
import {fontWeights} from '../../css/fontWeights'

// Types
import {MoxeButtonProps} from '../../types/props/MoxeButtonProps'

export const MoxeButton = (props: MoxeButtonProps) => {
  const {
    buttonId,
    text,
    moxeVariant,
    height,
    onClick,
    disabled,
    isLoading,
    customSx
  } = props

  const id = 'moxeButton' + buttonId
  // Adding 'any' type for now because the mui Button component
  // won't complain about the 'variant' type then
  let muiVariant: any, variantStyle

  switch (moxeVariant) {
    case 'primary':
      muiVariant = 'contained'
      variantStyle = {
        color: colors.gray[0],
        backgroundColor: colors.orange[500],
        '&:hover, &:active': {
          backgroundColor: colors.orange[600],
          boxShadow: 'none'
        },
        '&.Mui-disabled': {
          color: colors.orange[200],
          backgroundColor: colors.orange[300]
        }
      }
      break

    case 'secondary':
      muiVariant = 'outlined'
      variantStyle = {
        color: colors.gray[900],
        backgroundColor: colors.gray[0],
        border: `1px solid ${colors.gray[400]}`,
        '&:hover, &:active': {
          backgroundColor: colors.gray[50],
          borderColor: colors.gray[800],
          boxShadow: 'none'
        },
        '&.Mui-disabled': {
          color: colors.gray[500],
          backgroundColor: colors.gray[100],
          borderColor: colors.gray[300]
        }
      }
      break
  }

  const moxeButtonStyle = {
    fontWeight: fontWeights.md,
    fontSize: fontSizes.md,
    padding: '6px 12px',
    textTransform: 'none',
    height: height,
    lineHeight: '16px',
    borderRadius: '4px',
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none'
    },
    ...customSx,
    ...variantStyle
  }

  const buttonLabel = () => {
    const IsMoxeVariantPrimary = moxeVariant === 'primary'
    const isMoxeVariantSecondary = moxeVariant === 'secondary'

    return isLoading && (IsMoxeVariantPrimary || isMoxeVariantSecondary) ? (
      <Box>
        <MoxeIcon
          iconId='ButtonLoading'
          icon={faSpinner}
          color={IsMoxeVariantPrimary ? colors.gray[0] : colors.gray[500]}
          fontSize='12px'
          classes='fa-spin'
        />
        <Box sx={{display: 'inline-block', marginLeft: '8px'}}>{text}</Box>
      </Box>
    ) : (
      text
    )
  }

  return (
    <Button
      id={id}
      data-testid={id}
      variant={muiVariant}
      disableFocusRipple
      disableRipple
      sx={moxeButtonStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonLabel()}
    </Button>
  )
}
