// Material UI
import {Container} from '@mui/material'

//Components
import {InsightSupportingEvidenceHeader} from '../reusable/InsightSupportingEvidenceHeader'

// CSS
import {colors} from '../../css/colors'

export const InsightSupportingEvidenceEmpty = () => {
  const noEvidenceContainerStyle = {
    border: '1px',
    backgroundColor: colors.gray[0]
  }

  return (
    <Container
      id='insightSupportingEvidenceEmpty'
      data-testid='insightSupportingEvidenceEmpty'
      sx={{noEvidenceContainerStyle}}
    >
      <InsightSupportingEvidenceHeader isHeaderForEmptyScreen={true} />
    </Container>
  )
}
