// React
import {useContext} from 'react'
import {If, Then} from 'react-if'

// Material UI
import {Box, RadioGroup} from '@mui/material'

// Context
import {SelectedInsightContext} from '../../../../context/SelectedInsightContext'
import {ConfigurationContext} from '../../../../context/ConfigurationContext'

// Components
import {RadioOption} from '../../../reusable/RadioOption'
import {CommonLabel} from '../../../reusable/CommonLabel'
import {MoxeTextField} from '../../../reusable/MoxeTextField'

// CSS
import {colors} from '../../../../css/colors'
import {fontWeights} from '../../../../css/fontWeights'

export const RejectDiagnosisContent = () => {
  const {selectedInsight, updateInsight} = useContext(SelectedInsightContext)
  const {rejectionReasons} = useContext(ConfigurationContext)

  const clickRejectReason = (reasonInput: string) => {
    let selectedInsightCopy = {...selectedInsight}
    selectedInsightCopy.reasonInput = reasonInput
    updateInsight(selectedInsightCopy)
  }

  const handleNoteOnChange = (newValue: string) => {
    let selectedInsightCopy = {...selectedInsight}
    selectedInsightCopy.noteInput = newValue
    updateInsight(selectedInsightCopy)
  }

  const getRejectReasonOptionContainerStyle = (isOptionSelected: boolean) => {
    const colorStyling = !isOptionSelected
      ? {
          border: `1px solid ${colors.gray[300]}`,
          backgroundColor: colors.gray[0]
        }
      : {
          border: `1px solid ${colors.orange[500]}`,
          backgroundColor: colors.orange[50]
        }
    return {
      ...colorStyling,
      color: colors.gray[1000],
      fontWeight: fontWeights.sm,
      borderRadius: '4px',
      marginBottom: '2px'
    }
  }

  const rejectionReasonStyle = {
    padding: '7px 0',
    height: '16px',
    lineHeight: '16px',
    color: colors.gray[1000]
  }

  return (
    <Box sx={{padding: '0 12px 6px 12px'}}>
      <CommonLabel text='Reason' />
      <RadioGroup
        name='reject-diagnosis-radio-buttons-group'
        sx={{marginBottom: '10px'}}
      >
        {rejectionReasons.map((reason: string) => {
          const optionId = 'InsightRejectReason' + reason + selectedInsight.id
          const boxOptionId = 'box' + optionId
          const isReasonSelected = selectedInsight.reasonInput === reason
          return (
            <Box
              id={boxOptionId}
              data-testid={boxOptionId}
              sx={getRejectReasonOptionContainerStyle(isReasonSelected)}
              key={optionId}
            >
              <RadioOption
                optionId={optionId}
                text={reason}
                onChange={() => clickRejectReason(reason)}
                isSelected={isReasonSelected}
                shouldRemoveIcons={true}
                customStyle={rejectionReasonStyle}
              />
            </Box>
          )
        })}
      </RadioGroup>
      <If condition={selectedInsight.reasonInput === 'Other'}>
        <Then>
          <MoxeTextField
            textFieldId={'RejectDiagnosisOther' + selectedInsight.id}
            text={selectedInsight.noteInput}
            placeholderText='Why was it rejected?'
            onChange={(event) => handleNoteOnChange(event.target.value)}
          />
        </Then>
      </If>
    </Box>
  )
}
