import {fontWeights} from '../fontWeights'

export const leftPaneDropdownTitleStyle = {
  height: '16px',
  lineHeight: '16px',
  marginLeft: '6px',
  marginRight: '4px',
  fontWeight: fontWeights.xl,
  display: 'inline-flex'
}
