// React
import {useContext} from 'react'

// Context
import {AppContext} from '../../../context/AppContext'

// Material UI
import {Container} from '@mui/system'

// Components
import {InsightsLoading} from '../../loading/InsightsLoading'
import {InsightsEmpty} from '../../empty/InsightsEmpty'
import {Insights} from './Insights'
import {EncounterDetails} from './EncounterDetails'

export const LeftPane = () => {
  const {insights, isLoadingCriticalAppData} = useContext(AppContext)

  const leftPaneStyle = {
    position: 'relative',
    height: '100%'
  }

  let insightsComponent
  if (isLoadingCriticalAppData) {
    insightsComponent = <InsightsLoading />
  } else if (insights.length === 0) {
    insightsComponent = <InsightsEmpty />
  } else {
    insightsComponent = <Insights />
  }

  return (
    <Container
      id='leftPane'
      data-testid='leftPane'
      disableGutters
      sx={leftPaneStyle}
    >
      <EncounterDetails />
      {insightsComponent}
    </Container>
  )
}
