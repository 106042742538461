// React
import {useEffect, useContext, useState} from 'react'

// Context
import {AppContext} from '../../../context/AppContext'
import {SelectedInsightContext} from '../../../context/SelectedInsightContext'
import {SubmissionScreenContext} from '../../../context/SubmissionScreenContext'

// Material UI
import {List, ListItemButton, Collapse, Box} from '@mui/material'
import {Container} from '@mui/system'

// Font Awesome
import {faChevronUp, faChevronDown} from '@fortawesome/pro-regular-svg-icons'

// Components
import {MoxeIcon} from '@moxe/component-library'
import {MoxeButton} from '../../reusable/MoxeButton'
import {InsightListItemButton} from './InsightListItemButton'

// Types
import {Insight} from '../../../types/state/Insight'
import {InsightCategories} from '../../../types/state/InsightCategories'
import {InsightCategoryEnum} from '../../../types/state/InsightCategoryEnum'
import {InsightStatusEnum} from '../../../types/state/InsightStatusEnum'

// Utils
import {sortByCategoryTitle} from '../../../utils/sorting'
import {isInsightIncomplete} from '../../../utils/isInsightIncomplete'

// CSS
import {colors} from '../../../css/colors'
import {fontSizes} from '../../../css/fontSizes'
import {fontWeights} from '../../../css/fontWeights'
import {leftPaneDropdownHeaderStyle} from '../../../css/styles/leftPaneDropdownHeaderStyle'
import {leftPaneDropdownTitleStyle} from '../../../css/styles/leftPaneDropdownTitleStyle'

// Constants
import {constants} from '../../../constants/constants'

export const Insights = () => {
  const {insights} = useContext(AppContext)
  const {selectedInsight, updateInsight} = useContext(SelectedInsightContext)
  const {setShouldBeOnSubmissionScreen} = useContext(SubmissionScreenContext)
  const [insightCategories, setInsightCategories] = useState<InsightCategories>(
    []
  )

  useEffect(() => {
    // Group insights into dictionary where key=category and value=array of insights
    const insightsByCategory = insights.reduce((groups: any, insight) => {
      const {category} = insight
      var categoryGroup
      if (
        category === InsightCategoryEnum.HealthConditionHistory ||
        category === InsightCategoryEnum.ClinicalInference
      ) {
        categoryGroup = constants.HEALTH_CONDITION_CONFIRMATION
      } else {
        categoryGroup = category
      }
      groups[categoryGroup] = groups[categoryGroup] ?? []
      groups[categoryGroup].push(insight)
      return groups
    }, {})

    // Restructure category groups into an array of groups instead of a dictionary
    let tempCategories = []
    for (let cat in insightsByCategory) {
      tempCategories.push({
        category: cat,
        insights: insightsByCategory[cat],
        expanded: true
      })
    }

    // Sort the category groups in proper order
    tempCategories = sortByCategoryTitle(tempCategories)
    setInsightCategories(tempCategories)
  }, [])

  const clickInsightCategory = (index: number) => {
    // Manage the expand/collapse state of each category
    const categoryClicked = {...insightCategories[index]}
    categoryClicked.expanded = !categoryClicked.expanded
    const clonedCategories = [...insightCategories]
    clonedCategories[index] = categoryClicked
    setInsightCategories(clonedCategories)
  }

  const clickGoToSummaryScreen = () => {
    setShouldBeOnSubmissionScreen(true)
    if (isInsightIncomplete(selectedInsight)) {
      let selectedInsightCopy = {...selectedInsight}
      selectedInsightCopy.status = InsightStatusEnum.Incomplete
      updateInsight(selectedInsightCopy)
    }
  }

  const categoryContainerStyle = {
    marginBottom: '20px'
  }

  const categoryTotalStyle = {
    height: '15px',
    lineHeight: '15px',
    background: colors.gray[100],
    color: colors.gray[900],
    padding: '0 4px 0 4px',
    borderRadius: '3px',
    display: 'inline-flex',
    fontWeight: fontWeights.md
  }

  const continueToSubmissionButtonStyle = {
    position: 'absolute',
    bottom: 0,
    right: 0
  }

  return (
    <Box id='insightsSection' data-testid='insightsSection'>
      <List
        id='insightsGroupList'
        data-testid='insightsGroupList'
        sx={{paddingTop: '0px'}}
      >
        {insightCategories.map((cat: any, catIndex: number) => {
          const categoryName = cat.category
          const categoryContainerId = 'categoryContainer' + categoryName
          const categoryItemButtonId = 'button' + categoryName
          const categoryListId = 'list' + categoryName
          const categoryTotalId = 'total' + categoryName

          let iconProp
          if (cat.expanded) {
            iconProp = faChevronDown
          } else {
            iconProp = faChevronUp
          }

          return (
            <Container
              id={categoryContainerId}
              data-testid={categoryContainerId}
              disableGutters
              sx={categoryContainerStyle}
              key={categoryContainerId}
            >
              <ListItemButton
                id={categoryItemButtonId}
                data-testid={categoryItemButtonId}
                disableGutters
                sx={leftPaneDropdownHeaderStyle}
                onClick={() => clickInsightCategory(catIndex)}
              >
                <MoxeIcon
                  icon={iconProp}
                  color={colors.gray[800]}
                  fontSize={fontSizes.sm}
                />
                <Box sx={leftPaneDropdownTitleStyle}>{categoryName}</Box>
                <Box
                  id={categoryTotalId}
                  data-testid={categoryTotalId}
                  sx={categoryTotalStyle}
                >
                  {cat.insights.length}
                </Box>
              </ListItemButton>

              <Collapse in={cat.expanded}>
                <List
                  id={categoryListId}
                  data-testid={categoryListId}
                  disablePadding
                >
                  {cat.insights.map((insight: Insight) => (
                    <InsightListItemButton
                      insight={insight}
                      key={insight.name}
                    />
                  ))}
                </List>
              </Collapse>
            </Container>
          )
        })}
      </List>

      <Box sx={continueToSubmissionButtonStyle}>
        <MoxeButton
          buttonId='ContinueToSubmission'
          text='Continue to Submission'
          moxeVariant='primary'
          height='32px'
          onClick={() => clickGoToSummaryScreen()}
        />
      </Box>
    </Box>
  )
}
