// React
import {useContext} from 'react'
import {If, Then} from 'react-if'

// Material UI
import {Box} from '@mui/material'

// Context
import {StatusSectionContext} from '../../../context/StatusSectionContext'

// CSS
import {colors} from '../../../css/colors'
import {
  insightContainerStyle,
  insightHeaderStyle,
  insightNameStyle,
  noteContainerStyle,
  noteLabelStyle
} from '../../../css/styles/summaryInsightStyle'

export const RejectedStatusSectionContent = () => {
  const {statusGroup, areNotesDisplayed} = useContext(StatusSectionContext)

  const insightHeaderAndNameStyle = {
    ...insightHeaderStyle,
    ...insightNameStyle
  }

  const insightHeaderAndNameNotAsContainerStyle = {
    ...insightHeaderStyle,
    ...insightNameStyle,
    borderRadius: '4px 4px 0px 0px',
    borderBottom: `1px solid ${colors.gray[300]}`
  }

  return (
    <Box id='rejectedStatusSection' data-testid='rejectedStatusSection'>
      {statusGroup.insights.map((insight) => {
        const baseId = 'rejectedStatusSection'
        const insightContainerId = baseId + 'InsightContainer' + insight.name
        const insightNameId = baseId + 'InsightName' + insight.name
        const insightReasonContainerId =
          baseId + 'InsightReasonContainer' + insight.name
        const insightReasonId = baseId + 'InsightReason' + insight.name
        return (
          <Box
            id={insightContainerId}
            data-testid={insightContainerId}
            sx={insightContainerStyle}
            key={insight.id}
          >
            <Box
              id={insightNameId}
              data-testid={insightNameId}
              sx={
                areNotesDisplayed && insight.reasonSaved
                  ? insightHeaderAndNameNotAsContainerStyle
                  : insightHeaderAndNameStyle
              }
            >
              {insight.name}
            </Box>
            <If condition={areNotesDisplayed && insight.reasonSaved}>
              <Then>
                <Box
                  id={insightReasonContainerId}
                  data-testid={insightReasonContainerId}
                  sx={noteContainerStyle}
                >
                  <Box sx={noteLabelStyle}>{'Rejection reason:'}</Box>
                  <Box
                    id={insightReasonId}
                    data-testid={insightReasonId}
                    sx={{color: colors.gray[700]}}
                  >
                    {insight.reasonSaved +
                      (insight.noteSaved ? ' - ' + insight.noteSaved : '')}
                  </Box>
                </Box>
              </Then>
            </If>
          </Box>
        )
      })}
    </Box>
  )
}
