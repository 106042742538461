// React
import {useContext} from 'react'
import {If, Then} from 'react-if'

// Material UI
import {Box} from '@mui/material'

// Context
import {SelectedInsightContext} from '../../../../context/SelectedInsightContext'
import {ValidationContext} from '../../../../context/ValidationContext'

// Components
import {RejectDiagnosisContent} from './RejectDiagnosisContent'
import {RadioOption} from '../../../reusable/RadioOption'

// Types
import {ActionEnum} from '../../../../types/state/ActionEnum'

// CSS
import {colors} from '../../../../css/colors'

// Utils
import {defaultInsightValidation} from '../../../../utils/validation'

export const RejectDiagnosisOption = () => {
  const {selectedInsight, updateInsight} = useContext(SelectedInsightContext)
  const {shouldShowValidationStyling} = useContext(ValidationContext)

  const clickRejectOption = () => {
    let selectedInsightCopy = {...selectedInsight}
    selectedInsightCopy.actionInput = ActionEnum.Rejected
    selectedInsightCopy.selectedDiagnosis = null
    selectedInsightCopy.reasonInput = null
    selectedInsightCopy.noteInput = null
    selectedInsightCopy.isEditMode = false
    selectedInsightCopy.validation = defaultInsightValidation()
    updateInsight(selectedInsightCopy)
  }

  const getRejectSelectContainerStyle = () => {
    let borderColor = colors.red[300]
    if (
      selectedInsight.validation.isActionValid ||
      (!selectedInsight.validation.isActionValid &&
        !shouldShowValidationStyling)
    ) {
      if (selectedInsight.actionInput === ActionEnum.Rejected) {
        borderColor = colors.gray[400]
      } else {
        borderColor = colors.gray[300]
      }
    }
    return {
      borderRadius: '2px',
      backgroundColor:
        selectedInsight.actionInput === ActionEnum.Rejected
          ? colors.gray[50]
          : colors.gray[0],
      border: `1px solid ${borderColor}`,
      marginBottom: '16px'
    }
  }

  const diagnosisRejectionOptionId =
    'diagnosisRejectionOption' + selectedInsight.id

  return (
    <Box
      id={diagnosisRejectionOptionId}
      data-testid={diagnosisRejectionOptionId}
      sx={getRejectSelectContainerStyle()}
    >
      <RadioOption
        optionId={'InsightReject' + selectedInsight.id}
        text='None, reject'
        onChange={() => clickRejectOption()}
        isSelected={selectedInsight.actionInput === ActionEnum.Rejected}
      />
      <If condition={selectedInsight.actionInput === ActionEnum.Rejected}>
        <Then>
          <RejectDiagnosisContent />
        </Then>
      </If>
    </Box>
  )
}
