import {Insight} from '../types/state/Insight'
import {InsightStatusEnum} from '../types/state/InsightStatusEnum'

export const isInsightIncomplete = (insight: Insight) => {
  const statusKey = insight.status as keyof typeof InsightStatusEnum
  switch (InsightStatusEnum[statusKey]) {
    case InsightStatusEnum.Incomplete:
      return true
    case InsightStatusEnum.Accepted:
    case InsightStatusEnum.Rejected:
      return false
    default:
      return (
        insight.actionInput !== null ||
        insight.selectedDiagnosis !== null ||
        insight.reasonInput ||
        insight.noteInput
      )
  }
}
