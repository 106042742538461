// React
import {useState, useContext} from 'react'
import {Else, If, Then} from 'react-if'

// Material UI
import {Grid} from '@mui/material'

// Components
import {LeftPane} from './panes/leftPane/LeftPane'
import {InsightForm} from './panes/insightForm/InsightForm'
import {InsightSupportingEvidence} from './panes/insightSupportingEvidence/InsightSupportingEvidence'
import {Summary} from './panes/summary/Summary'
import {InsightFormLoading} from './loading/InsightFormLoading'
import {InsightSupportingEvidenceLoading} from './loading/InsightSupportingEvidenceLoading'
import {InsightFormEmpty} from './empty/InsightFormEmpty'
import {InsightSupportingEvidenceEmpty} from './empty/InsightSupportingEvidenceEmpty'

// Context
import {AppContext} from '../context/AppContext'
import {SelectedInsightContext} from '../context/SelectedInsightContext'
import {SubmissionScreenContext} from '../context/SubmissionScreenContext'
import {EncounterDetailsContext} from '../context/EncounterDetailsContext'

// Types
import {Insight} from '../types/state/Insight'

// CSS
import {colors} from '../css/colors'

export const AppContent = () => {
  const {insights, setInsights, isLoadingCriticalAppData} =
    useContext(AppContext)
  const [selectedInsightIndex, setSelectedInsightIndex] = useState<number>(0)
  const [shouldBeOnSubmissionScreen, setShouldBeOnSubmissionScreen] =
    useState<boolean>(false)
  const [commentToProvider, setCommentToProvider] = useState<string | null>(
    null
  )

  // This method will update the index to be the index of the newly selected insight.
  // The "selectedInsight" value that gets passed down in the context is evaluated based on this index value.
  const updateSelectedInsight = (newInsight: Insight) => {
    const previousVersionOfInsight = insights.find(
      (insight) => newInsight.id === insight.id
    )
    if (previousVersionOfInsight) {
      const insightIndex = insights.indexOf(previousVersionOfInsight)
      setSelectedInsightIndex(insightIndex)
    }
  }

  // This method can be used any time we need to update an insight in the list of insights,
  // even if it's not the selected insight.
  const updateInsight = (insight: Insight) => {
    let insightsCopy = [...insights]
    let previousVersionOfInsightCopy = insightsCopy.find(
      (insightCopy) => insightCopy.id === insight.id
    )
    if (previousVersionOfInsightCopy) {
      previousVersionOfInsightCopy.isEditMode = insight.isEditMode
      previousVersionOfInsightCopy.actionInput = insight.actionInput
      previousVersionOfInsightCopy.reasonInput = insight.reasonInput
      previousVersionOfInsightCopy.noteInput = insight.noteInput
      previousVersionOfInsightCopy.selectedDiagnosis = insight.selectedDiagnosis
      previousVersionOfInsightCopy.previousAction = insight.previousAction
      previousVersionOfInsightCopy.previousDiagnosis = insight.previousDiagnosis
      previousVersionOfInsightCopy.previousReason = insight.previousReason
      previousVersionOfInsightCopy.previousNote = insight.previousNote
      previousVersionOfInsightCopy.status = insight.status
      previousVersionOfInsightCopy.actionSaved = insight.actionSaved
      previousVersionOfInsightCopy.reasonSaved = insight.reasonSaved
      previousVersionOfInsightCopy.noteSaved = insight.noteSaved
      previousVersionOfInsightCopy.selectedDiagnosisSaved =
        insight.selectedDiagnosisSaved
      previousVersionOfInsightCopy.validation = insight.validation
      previousVersionOfInsightCopy.isConfirmed = insight.isConfirmed
      setInsights(insightsCopy)
    }
  }

  const leftPaneStyle = {
    padding: '12px',
    background: colors.gray[50]
  }

  const secondPaneStyle = {
    padding: '12px 24px',
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 12px rgba(0, 0, 0, 0.1)'
  }

  const supportingEvidenceStyle = {
    padding: '12px',
    backgroundColor: colors.gray[0],
    borderLeft: `1px solid ${colors.gray[300]}`
  }

  const selectedInsightContext = {
    selectedInsight: insights[selectedInsightIndex],
    updateSelectedInsight,
    updateInsight
  }

  const submissionScreenContext = {
    shouldBeOnSubmissionScreen,
    setShouldBeOnSubmissionScreen
  }

  const encounterDetailsContext = {
    commentToProvider,
    setCommentToProvider
  }

  let pane2, pane3
  if (isLoadingCriticalAppData) {
    pane2 = <InsightFormLoading />
    pane3 = <InsightSupportingEvidenceLoading />
  } else if (insights.length === 0) {
    pane2 = <InsightFormEmpty />
    pane3 = <InsightSupportingEvidenceEmpty />
  } else {
    pane2 = <InsightForm />
    pane3 = <InsightSupportingEvidence />
  }

  return (
    <Grid
      id='appContentGrid'
      data-testid='appContentGrid'
      container
      spacing={0}
    >
      <SelectedInsightContext.Provider value={selectedInsightContext}>
        <SubmissionScreenContext.Provider value={submissionScreenContext}>
          <EncounterDetailsContext.Provider value={encounterDetailsContext}>
            <Grid
              id='leftGridItem'
              data-testid='leftGridItem'
              item
              xs={3.25}
              minHeight='100vh'
              sx={leftPaneStyle}
            >
              <LeftPane />
            </Grid>

            <If condition={shouldBeOnSubmissionScreen}>
              <Then>
                <Grid
                  id='summaryGridItem'
                  data-testid='summaryGridItem'
                  item
                  xs={8.5}
                  sx={secondPaneStyle}
                >
                  <Summary />
                </Grid>
              </Then>
              <Else>
                <Grid
                  id='formGridItem'
                  data-testid='formGridItem'
                  item
                  xs={6.25}
                  sx={secondPaneStyle}
                >
                  {pane2}
                </Grid>
                <Grid
                  id='supportingEvidenceGridItem'
                  data-testid='supportingEvidenceGridItem'
                  item
                  xs={2.5}
                  sx={supportingEvidenceStyle}
                >
                  {pane3}
                </Grid>
              </Else>
            </If>
          </EncounterDetailsContext.Provider>
        </SubmissionScreenContext.Provider>
      </SelectedInsightContext.Provider>
    </Grid>
  )
}
