// React
import {useContext, useEffect, useState} from 'react'
import {If, Then} from 'react-if'

// Components
import {MoxeButton} from '../../reusable/MoxeButton'
import {MoxeIcon} from '@moxe/component-library'
import {StatusSection} from './StatusSection'
import {IncompleteModalContent} from './IncompleteModalContent'
import {SuccessModalContent} from './SuccessModalContent'
import {ErrorModalContent} from './ErrorModalContent'
import {OopsModalContent} from './OopsModalContent'

// Material UI
import {Box, Checkbox, FormControlLabel, Modal} from '@mui/material'

// Font Awesome
import {faTimes} from '@fortawesome/pro-regular-svg-icons'

// Constants
import {
  getSubmitForReviewEndpoint,
  getReviewAndSignEndpoint
} from '../../../constants/api/apiEndpoints'
import {getConvergenceApiHost} from '../../../constants/api/apiHosts'
import {
  getOptionsWithAuthorizationHeader,
  getOptionsWithAuthorizationHeaderAndBody
} from '../../../constants/api/apiOptions'
import {submitForReviewSnackbarOptions} from '../../../constants/submitForReviewSnackbarOptions'

// Context
import {AppContext} from '../../../context/AppContext'
import {ConfigurationContext} from '../../../context/ConfigurationContext'
import {EncounterDetailsContext} from '../../../context/EncounterDetailsContext'
import {SnackbarContext} from '../../../context/SnackbarContext'
import {StatusSectionContext} from '../../../context/StatusSectionContext'
import {SummaryModalContext} from '../../../context/SummaryModalContext'

// Utils
import {moxeFetch} from '../../../utils/api/moxeFetch'
import {hasPermission} from '../../../utils/hasPermission'
import {CustomError} from '../../../utils/api/CustomError'

// Types
import {InsightStatusGroup} from '../../../types/state/InsightStatusGroup'
import {StatusGroupValueToSummaryDisplayNameEnum} from '../../../types/state/StatusGroupValueToSummaryDisplayNameEnum'
import {Insight} from '../../../types/state/Insight'
import {StatusGroupEnum} from '../../../types/state/StatusGroupEnum'
import {InsightStatusEnum} from '../../../types/state/InsightStatusEnum'
import {InsightCategoryEnum} from '../../../types/state/InsightCategoryEnum'
import {MoxeApiInnerError} from '../../../types/api/responses/MoxeApiResponse'

// CSS
import {fontWeights} from '../../../css/fontWeights'
import {fontSizes} from '../../../css/fontSizes'
import {colors} from '../../../css/colors'

export const Summary = () => {
  const {insights, token, physician} = useContext(AppContext)
  const {userPermissions, userRole} = useContext(ConfigurationContext)
  const {commentToProvider} = useContext(EncounterDetailsContext)
  const {setSnackbarOptions} = useContext(SnackbarContext)

  const [statusGroups, setStatusGroups] = useState<InsightStatusGroup[]>([])
  const [areNotesDisplayed, setAreNotesDisplayed] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [modalType, setModalType] = useState<
    'success' | 'incomplete' | 'error' | 'oops' | null
  >(null)
  const [incompleteInsights, setIncompleteInsights] = useState<Insight[]>([])
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
  const [signErrors, setSignErrors] = useState<MoxeApiInnerError[]>([])

  const dateString: string = new Date().toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })

  useEffect(() => {
    // Group insights into dictionary where key=summary status and value=array of insights
    if (userRole === 'Clinician') {
      insights.forEach((insight) => {
        switch (insight.status) {
          case InsightStatusEnum.Incomplete:
            insight.summaryScreenStatus = StatusGroupEnum.Incomplete
            break
          case InsightStatusEnum.NotAddressed:
            insight.summaryScreenStatus = StatusGroupEnum.NotAddressed
            break
          case InsightStatusEnum.Accepted:
            insight.summaryScreenStatus = StatusGroupEnum.Accepted
            break
          case InsightStatusEnum.Rejected:
            insight.summaryScreenStatus = StatusGroupEnum.Rejected
            break
          default:
            insight.summaryScreenStatus = StatusGroupEnum.NotAddressed
        }
      })
    } else {
      insights.forEach((insight) => {
        switch (insight.status) {
          case InsightStatusEnum.Incomplete:
            insight.summaryScreenStatus = StatusGroupEnum.Incomplete
            if (insight.category !== InsightCategoryEnum.QualityMeasures) {
              if (insight.selectedDiagnosisSaved) {
                insight.summaryScreenStatus = StatusGroupEnum.ReadyForReview
              } else {
                insight.summaryScreenStatus = StatusGroupEnum.Unsaved
              }
            } else {
              if (insight.actionSaved) {
                insight.summaryScreenStatus = StatusGroupEnum.ReadyForReview
              } else {
                insight.summaryScreenStatus = StatusGroupEnum.Unsaved
              }
            }
            break
          case InsightStatusEnum.NotAddressed:
            insight.summaryScreenStatus = StatusGroupEnum.NotAddressed
            break
          case InsightStatusEnum.Accepted:
            insight.summaryScreenStatus = StatusGroupEnum.Accepted
            break
          case InsightStatusEnum.Rejected:
            insight.summaryScreenStatus = StatusGroupEnum.Rejected
            break
          default:
            insight.summaryScreenStatus = StatusGroupEnum.NotAddressed
        }
      })
    }
    const insightsByStatus = insights.reduce((groups: any, insight) => {
      const {summaryScreenStatus} = insight
      groups[summaryScreenStatus] = groups[summaryScreenStatus] ?? []
      groups[summaryScreenStatus].push(insight)
      return groups
    }, {})

    // Update status groups state with groups of insights by status
    let statusGroups: InsightStatusGroup[] = []
    const insightStatusKeys = Object.keys(StatusGroupEnum)
    insightStatusKeys.forEach((key) => {
      const statusKey = key as keyof typeof StatusGroupEnum
      if (insightsByStatus[statusKey]) {
        let statusValue = StatusGroupEnum[statusKey]
        let colorGroup
        switch (statusValue) {
          case StatusGroupEnum.Incomplete:
          case StatusGroupEnum.NotAddressed:
          case StatusGroupEnum.Unsaved:
            colorGroup = colors.yellow
            break
          case StatusGroupEnum.Accepted:
          case StatusGroupEnum.ReadyForReview:
            colorGroup = colors.green
            break
          case StatusGroupEnum.Rejected:
            colorGroup = colors.red
            break
        }
        statusGroups.push({
          status: StatusGroupValueToSummaryDisplayNameEnum[statusValue],
          insights: insightsByStatus[statusKey],
          colorGroup: colorGroup
        })
      }
    })
    setStatusGroups(statusGroups)
  }, [])

  const clickSignAndSubmit = () => {
    setIsSubmitLoading(true)
    const incompleteGroup = statusGroups.find(
      (group) =>
        group.status === StatusGroupValueToSummaryDisplayNameEnum.Incomplete
    )
    if (incompleteGroup && incompleteGroup.insights.length > 0) {
      setIncompleteInsights(incompleteGroup.insights)
      setModalType('incomplete')
      setIsSubmitLoading(false)
      setIsModalOpen(true)
    } else {
      moxeFetch<any>(
        getConvergenceApiHost() + getReviewAndSignEndpoint(),
        getOptionsWithAuthorizationHeader('put', token)
      )
        .then((_) => {
          setModalType('success')
        })
        .catch((error: CustomError) => {
          setSignErrors(error.errorObject.errors)
          setModalType('error')
        })
        .finally(() => {
          setIsSubmitLoading(false)
          setIsModalOpen(true)
        })
    }
  }

  const clickSubmitForReview = () => {
    if (hasPermission(userPermissions, 'EDIT_COMMENT_TO_PROVIDER')) {
      setIsSubmitLoading(true)
      moxeFetch<any>(
        getConvergenceApiHost() + getSubmitForReviewEndpoint(),
        getOptionsWithAuthorizationHeaderAndBody('post', token, {
          commentToProvider: commentToProvider
        })
      )
        .then(() => {
          setSnackbarOptions(submitForReviewSnackbarOptions)
        })
        .catch(() => {
          setModalType('oops')
          setIsModalOpen(true)
        })
        .finally(() => {
          setIsSubmitLoading(false)
        })
    } else {
      setSnackbarOptions(submitForReviewSnackbarOptions)
    }
  }

  const getModalContent = () => {
    switch (modalType) {
      case 'success':
        return <SuccessModalContent />
      case 'incomplete':
        return (
          <IncompleteModalContent
            incompleteInsights={incompleteInsights}
            setIsModalOpen={setIsModalOpen}
          />
        )
      case 'error':
        return <ErrorModalContent signErrors={signErrors} />
      case 'oops':
        return <OopsModalContent setIsModalOpen={setIsModalOpen} />
      default:
        return null
    }
  }

  const summaryHeaderStyle = {
    fontWeight: fontWeights.lg,
    fontSize: fontSizes.xl,
    marginBottom: '8px'
  }

  const summaryHeaderDescriptionStyle = {
    fontWeight: fontWeights.sm,
    marginBottom: '8px'
  }

  const displayNotesCheckboxContainer = {
    padding: '0px 12px',
    marginBottom: '12px',
    border: `1px solid ${colors.gray[100]}`,
    borderRadius: '8px',
    '.MuiCheckbox-root': {
      padding: '12px',
      color: colors.gray[400],
      borderRadius: '3px',
      '&.Mui-checked': {
        color: colors.orange[500]
      }
    }
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '451px',
    color: colors.gray[1000],
    background: colors.gray[0],
    borderRadius: '4px',
    boxShadow:
      '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)'
  }

  const xContainerStyling = {
    position: 'absolute',
    top: '8px',
    right: '11px',
    ':hover': {
      cursor: 'pointer'
    }
  }

  const summaryModalContext = {
    setModalType
  }

  const hasSignPermission = hasPermission(
    userPermissions,
    'SIGN_AND_ADD_TO_ENCOUNTER'
  )
  let submitButtonLabel, summaryTitle, submitButtonOnClick: () => void
  if (hasSignPermission) {
    submitButtonLabel = 'Sign & Add to Encounter'
    submitButtonOnClick = () => clickSignAndSubmit()
    summaryTitle =
      "Please review the patient assessment below and document your findings in the patient's medical record by clicking Sign & Submit."
  } else {
    submitButtonLabel = 'Submit for Review'
    submitButtonOnClick = () => clickSubmitForReview()
    summaryTitle =
      'Please review the patient assessment below and save for physician review by clicking Submit for Review'
  }

  return (
    <Box id='summaryPane' data-testid='summaryPane'>
      <Box
        id='summaryHeader'
        data-testid='summaryHeader'
        sx={summaryHeaderStyle}
      >
        Review & Document
      </Box>
      <Box
        id='summaryHeaderDescription'
        data-testid='summaryHeaderDescription'
        sx={summaryHeaderDescriptionStyle}
      >
        {summaryTitle}
      </Box>
      <Box sx={displayNotesCheckboxContainer}>
        <FormControlLabel
          id='displayNotesCheckbox'
          data-testid='displayNotesCheckbox'
          control={
            <Checkbox
              onChange={() => setAreNotesDisplayed(!areNotesDisplayed)}
            />
          }
          label='Click to show notes related to the addressed conditions and quality measures.'
        />
      </Box>
      <Box sx={{marginBottom: '37px'}}>
        {statusGroups.map((statusGroup) => {
          return (
            <StatusSectionContext.Provider
              value={{
                statusGroup: statusGroup,
                areNotesDisplayed: areNotesDisplayed
              }}
              key={statusGroup.status}
            >
              <StatusSection />
            </StatusSectionContext.Provider>
          )
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: '10px',
          alignItems: 'center',
          alignContent: 'center',
          height: '32px'
        }}
      >
        <MoxeButton
          buttonId='Submit'
          text={submitButtonLabel}
          moxeVariant='primary'
          height='32px'
          onClick={() => submitButtonOnClick()}
          isLoading={isSubmitLoading}
          disabled={isSubmitLoading}
        />
        {hasSignPermission && (
          <Box
            id='signingDoctorInfo'
            data-testid='signingDoctorInfo'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              gap: '5px',
              alignItems: 'center',
              alignContent: 'center',
              backgroundColor: colors.gray[50],
              height: 'inherit',
              padding: '0px 8px',
              borderRadius: '8px',
              fontSize: fontSizes['md'],
              fontWeight: fontWeights['sm'],
              border: '1px solid ' + colors.gray[200]
            }}
          >
            <Box sx={{fontWeight: fontWeights['sm']}}>Documented by</Box>
            <Box
              id='signingDoctor'
              data-testid='signingDoctor'
              sx={{fontWeight: fontWeights['md']}}
            >
              {physician.name}
            </Box>
            <Box
              id='npi'
              data-testid='signingNPI'
              sx={{fontSize: fontSizes['md'], fontWeight: fontWeights['md']}}
            >
              (NPI {physician.npi})
            </Box>
            <Box>|</Box>
            <Box id='signingDate' data-testid='signingDate'>
              {dateString}
            </Box>
          </Box>
        )}
      </Box>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box sx={modalStyle}>
          <SummaryModalContext.Provider value={summaryModalContext}>
            <If condition={modalType && modalType !== 'incomplete'}>
              <Then>
                <Box
                  onClick={() => setIsModalOpen(false)}
                  sx={xContainerStyling}
                >
                  <MoxeIcon
                    icon={faTimes}
                    color={colors.gray[600]}
                    fontSize='16px'
                  />
                </Box>
              </Then>
            </If>
            {getModalContent()}
          </SummaryModalContext.Provider>
        </Box>
      </Modal>
    </Box>
  )
}
