// React
import {useContext} from 'react'

// Components
import {MoxeIcon} from '@moxe/component-library'

// Context

// Material UI
import {Box} from '@mui/material'

// Font Awesome
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {faExclamationTriangle} from '@fortawesome/pro-regular-svg-icons'

// CSS
import {fontWeights} from './css/fontWeights'
import {colors} from './css/colors'
import {fontSizes} from './css/fontSizes'

// Utils
import {MoxeModalContext} from './context/MoxeModalContext'
import {MoxeModalContextState} from './types/state/MoxeModalContextState'

const errorIcon = faExclamationTriangle as IconProp

export const MoxeErrorModalContent = () => {
  const {modalContent} = useContext<MoxeModalContextState>(MoxeModalContext)
  const contentStyle = {
    padding: '16px',
    textAlign: 'center',
    color: colors.gray[900]
  }

  const errorLabelStyle = {
    margin: '16px 0px 12px 0px',
    color: colors.red[500],
    fontWeight: fontWeights.xl,
    fontSize: fontSizes.xl
  }

  return (
    <Box
      id='moxeErrorModalContent'
      data-testid='moxeErrorModalContent'
      sx={contentStyle}
    >
      <MoxeIcon icon={errorIcon} color={colors.red[500]} fontSize='56px' />
      <Box sx={errorLabelStyle}>Error!</Box>
      <Box>{modalContent}</Box>
    </Box>
  )
}
