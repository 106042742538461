import {Box, Skeleton} from '@mui/material'

export const EncounterDetailsLoading = () => {
  const getPatientDetailItemStyle = (index: number) => {
    return {
      width: '70px',
      display: 'inline-block',
      margin: index !== 2 ? '6px 32px 6px 0px' : '6px 0px'
    }
  }

  const skeletonBaseStyle = {
    borderRadius: '4px',
    backgroundColor: '#0000000d'
  }

  const getPatientDetailTextStyle = (index: number) => {
    return {
      ...skeletonBaseStyle,
      height: '19px',
      marginBottom: index === 0 ? '4px' : '0px'
    }
  }

  const commentToProviderLabelSkeletonStyle = {
    ...skeletonBaseStyle,
    marginBottom: '4px',
    height: '17px'
  }

  const commentToProviderSkeletonStyle = {
    ...skeletonBaseStyle,
    height: '76px'
  }

  const encounterDetailsLoadingId = 'encounterDetailsLoading'

  return (
    <Box
      id={encounterDetailsLoadingId}
      data-testid={encounterDetailsLoadingId}
      sx={{marginBottom: '18px'}}
    >
      <Box sx={{marginBottom: '2px'}}>
        {[...Array(3)].map((_, outerIndex) => {
          const patientDetailItemId = 'patientDetailItem' + outerIndex
          return (
            <Box
              id={patientDetailItemId}
              data-testid={patientDetailItemId}
              sx={getPatientDetailItemStyle(outerIndex)}
              key={patientDetailItemId}
            >
              {[...Array(2)].map((_, innerIndex) => {
                const patientDetailTextSkeletonId =
                  'patientDetailTextSkeleton' + innerIndex
                return (
                  <Skeleton
                    id={patientDetailTextSkeletonId}
                    data-testid={patientDetailTextSkeletonId}
                    animation='wave'
                    variant='rectangular'
                    sx={getPatientDetailTextStyle(innerIndex)}
                    key={patientDetailTextSkeletonId}
                  />
                )
              })}
            </Box>
          )
        })}
      </Box>
      <Skeleton
        animation='wave'
        variant='rectangular'
        sx={commentToProviderLabelSkeletonStyle}
      />
      <Skeleton
        animation='wave'
        variant='rectangular'
        sx={commentToProviderSkeletonStyle}
      />
    </Box>
  )
}
