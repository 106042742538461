// React
import {useContext, useState} from 'react'

// Components
import {MoxeButton} from '../../reusable/MoxeButton'
import {MoxeIcon} from '@moxe/component-library'

// Material UI
import {Box} from '@mui/material'

// Font Awesome
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons'

// Context
import {AppContext} from '../../../context/AppContext'
import {SnackbarContext} from '../../../context/SnackbarContext'
import {EncounterDetailsContext} from '../../../context/EncounterDetailsContext'

// Constants
import {getConvergenceApiHost} from '../../../constants/api/apiHosts'
import {getSubmitForReviewEndpoint} from '../../../constants/api/apiEndpoints'
import {getOptionsWithAuthorizationHeaderAndBody} from '../../../constants/api/apiOptions'
import {submitForReviewSnackbarOptions} from '../../../constants/submitForReviewSnackbarOptions'

// CSS
import {fontSizes} from '../../../css/fontSizes'
import {fontWeights} from '../../../css/fontWeights'
import {colors} from '../../../css/colors'

// Utils
import {moxeFetch} from '../../../utils/api/moxeFetch'

export const OopsModalContent = (props: {
  setIsModalOpen: (isModalOpen: boolean) => void
}) => {
  const {setIsModalOpen} = props

  const {token} = useContext(AppContext)
  const {setSnackbarOptions} = useContext(SnackbarContext)
  const {commentToProvider} = useContext(EncounterDetailsContext)

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)

  const clickRetrySavingComment = () => {
    setIsSubmitLoading(true)
    moxeFetch<any>(
      getConvergenceApiHost() + getSubmitForReviewEndpoint(),
      getOptionsWithAuthorizationHeaderAndBody('post', token, {
        commentToProvider: commentToProvider
      })
    )
      .then(() => {
        setIsModalOpen(false)
        setSnackbarOptions(submitForReviewSnackbarOptions)
      })
      .catch(() => {
        // Nothing new needs to happen. Same exact stuff should happen
        // except the button shouldn't be loading anymore, which will
        // get reset in the finally block
      })
      .finally(() => {
        setIsSubmitLoading(false)
      })
  }

  const contentStyle = {
    padding: '36px 16px 16px 16px',
    textAlign: 'center',
    color: colors.gray[900]
  }

  const oopsLabelStyle = {
    margin: '23px 0px 8px 0px',
    fontWeight: fontWeights.xl,
    fontSize: fontSizes.lg
  }

  const buttonsContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between'
  }

  const customButtonWidth = {
    width: '203px'
  }

  const headerTextId = 'oopsModalHeaderText'
  const somethingWentWrongMessageId = 'oopsModalSomethingWentWrongMessage'

  return (
    <Box id='oopsModalContent' data-testid='oopsModalContent' sx={contentStyle}>
      <MoxeIcon
        icon={faExclamationTriangle}
        color={colors.gray[500]}
        fontSize='56px'
      />
      <Box id={headerTextId} data-testid={headerTextId} sx={oopsLabelStyle}>
        Oops!
      </Box>
      <Box
        id={somethingWentWrongMessageId}
        data-testid={somethingWentWrongMessageId}
        sx={{marginBottom: '16px'}}
      >
        Something went wrong while trying to save your comment to the physician.
        Feel free to try submitting it again or proceed without it. If this
        issue keeps happening, reach out to Moxe support for assistance.
      </Box>
      <Box sx={buttonsContainerStyle}>
        <MoxeButton
          buttonId='RetrySavingComment'
          text='Retry saving comment'
          moxeVariant='secondary'
          height='32px'
          onClick={() => clickRetrySavingComment()}
          isLoading={isSubmitLoading}
          disabled={isSubmitLoading}
          customSx={customButtonWidth}
        />
        <MoxeButton
          buttonId='ContinueWithoutComment'
          text='Continue without comment'
          moxeVariant='secondary'
          height='32px'
          onClick={() => setIsModalOpen(false)}
          customSx={customButtonWidth}
        />
      </Box>
    </Box>
  )
}
