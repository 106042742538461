// React
import {useContext, useState} from 'react'

// Components
import {MoxeButton} from '../../reusable/MoxeButton'
import {MoxeIcon} from '@moxe/component-library'

// Material UI
import {Box, ListItem} from '@mui/material'

// Font Awesome
import {faFileTimes} from '@fortawesome/pro-regular-svg-icons'

// Context
import {AppContext} from '../../../context/AppContext'
import {SummaryModalContext} from '../../../context/SummaryModalContext'

// Types
import {Insight} from '../../../types/state/Insight'

// CSS
import {fontWeights} from '../../../css/fontWeights'
import {colors} from '../../../css/colors'

// Utils
import {moxeFetch} from '../../../utils/api/moxeFetch'
import {getConvergenceApiHost} from '../../../constants/api/apiHosts'
import {getReviewAndSignEndpoint} from '../../../constants/api/apiEndpoints'
import {getOptionsWithAuthorizationHeader} from '../../../constants/api/apiOptions'

export const IncompleteModalContent = (props: {
  incompleteInsights: Insight[]
  setIsModalOpen: (isModalOpen: boolean) => void
}) => {
  const {incompleteInsights, setIsModalOpen} = props

  const {token} = useContext(AppContext)
  const {setModalType} = useContext(SummaryModalContext)

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)

  const clickConfirmAndSign = () => {
    setIsSubmitLoading(true)
    moxeFetch<any>(
      getConvergenceApiHost() + getReviewAndSignEndpoint(),
      getOptionsWithAuthorizationHeader('put', token)
    )
      .then((_) => {
        setModalType('success')
      })
      .catch((_) => {
        setModalType('error')
      })
      .finally(() => {
        setIsSubmitLoading(false)
      })
  }

  const headerStyle = {
    padding: '16px',
    background: colors.gray[50],
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  }

  const headerTextStyle = {
    display: 'inline-flex',
    marginLeft: '12px',
    fontWeight: fontWeights.xl
  }

  const detailStyle = {
    padding: '12px 16px 16px 16px',
    borderBottomLeftRadius: '4px',
    borderTopLeftRadius: '4px'
  }

  const insightListItemStyle = {
    display: 'list-item',
    color: colors.yellow[500]
  }

  const confirmButtonBoxStyle = {
    display: 'inline-block',
    marginRight: '16px'
  }

  const incompleteCountMessage =
    incompleteInsights.length === 1
      ? 'There is 1 incomplete change:'
      : `There are ${incompleteInsights.length} incomplete changes:`

  return (
    <Box id='incompleteModalContent' data-testid='incompleteModalContent'>
      <Box
        id='incompleteModalHeader'
        data-testid='incompleteModalHeader'
        sx={headerStyle}
      >
        <MoxeIcon
          icon={faFileTimes}
          color={colors.yellow[500]}
          fontSize='16px'
        />
        <Box sx={headerTextStyle}>You still have incomplete conditions</Box>
      </Box>
      <Box
        id='incompleteModalDetails'
        data-testid='incompleteModalDetails'
        sx={detailStyle}
      >
        <Box sx={{fontWeight: fontWeights.md}}>
          <Box>{incompleteCountMessage}</Box>
          {incompleteInsights.map((insight) => (
            <ListItem sx={insightListItemStyle} key={insight.id}>
              {insight.name}
            </ListItem>
          ))}
          <Box sx={{margin: '12px 0px'}}>
            If you would like to address them, press the “Cancel” button.
          </Box>
        </Box>
        <Box sx={{marginBottom: '12px'}}>
          You are signing the reviewed documentation and confirming its
          accuracy.
        </Box>
        <Box sx={{marginBottom: '20px'}}>
          By clicking Confirm and Sign, you are adding your clinical opinion to
          the patient’s encounter.
        </Box>
        <Box sx={confirmButtonBoxStyle}>
          <MoxeButton
            buttonId='ConfirmAndSign'
            text='Confirm and sign'
            moxeVariant='primary'
            height='32px'
            onClick={() => clickConfirmAndSign()}
            isLoading={isSubmitLoading}
            disabled={isSubmitLoading}
          />
        </Box>
        <MoxeButton
          buttonId='CancelSign'
          text='Cancel'
          moxeVariant='secondary'
          height='32px'
          onClick={() => setIsModalOpen(false)}
        />
      </Box>
    </Box>
  )
}
