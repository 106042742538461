// Components
import {MoxeIcon} from '../../reusable/MoxeIcon'
import {DiagnosisWritebackErrors} from './DiagnosisWritebackErrors'

// Material UI
import {Box} from '@mui/material'

// Font Awesome
import {faExclamationTriangle} from '@fortawesome/pro-regular-svg-icons'

// Types
import {MoxeApiInnerError} from '../../../types/api/responses/MoxeApiResponse'

// CSS
import {fontWeights} from '../../../css/fontWeights'
import {colors} from '../../../css/colors'
import {fontSizes} from '../../../css/fontSizes'

export const ErrorModalContent = (props: {signErrors: MoxeApiInnerError[]}) => {
  const {signErrors} = props

  const buildContent = () => {
    const dxWritebackErrors = signErrors
      .filter((error) =>
        error.message.includes(
          'Failed to write the icd10 back to the visit diagnoses in the EMR'
        )
      )
      .map((error) => error.message)
    if (dxWritebackErrors.length > 0) {
      return <DiagnosisWritebackErrors dxWritebackErrors={dxWritebackErrors} />
    } else {
      return <Box>Your work was not submitted due to an error.</Box>
    }
  }

  const contentStyle = {
    padding: '16px',
    textAlign: 'center',
    color: colors.gray[900]
  }

  const errorLabelStyle = {
    margin: '16px 0px 12px 0px',
    color: colors.red[500],
    fontWeight: fontWeights.xl,
    fontSize: fontSizes.xl
  }

  return (
    <Box
      id='errorModalContent'
      data-testid='errorModalContent'
      sx={contentStyle}
    >
      <MoxeIcon
        iconId='SummaryModalError'
        icon={faExclamationTriangle}
        color={colors.red[500]}
        fontSize='56px'
      />
      <Box sx={errorLabelStyle}>Error!</Box>
      {buildContent()}
    </Box>
  )
}
