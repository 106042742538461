// React
import {useContext} from 'react'
import {Else, If, Then} from 'react-if'

// Material UI
import {List, ListItem, Typography, Container, Grid, Box} from '@mui/material'

// Font Awesome
import {
  faCalendar,
  faUser,
  faCabinetFiling,
  faHospital
} from '@fortawesome/pro-regular-svg-icons'
import {IconProp} from '@fortawesome/fontawesome-svg-core'

// Context
import {SelectedInsightContext} from '../../../context/SelectedInsightContext'

// Components
import {InsightSupportingEvidenceHeader} from '../../reusable/InsightSupportingEvidenceHeader'
import {MoxeIcon} from '@moxe/component-library'

// Types
import {SupportingEvidence} from '../../../types/api/responses/SupportingEvidence'

// CSS
import {colors} from '../../../css/colors'
import {fontSizes} from '../../../css/fontSizes'

const calendarIcon = faCalendar as IconProp
const userIcon = faUser as IconProp
const cabinetFilingIcon = faCabinetFiling as IconProp
const hospitalIcon = faHospital as IconProp

export const InsightSupportingEvidence = () => {
  const {selectedInsight} = useContext(SelectedInsightContext)

  if (selectedInsight === undefined) {
    return (
      <Typography
        id='evidenceNoInsightSelectedText'
        data-testid='evidenceNoInsightSelectedText'
        variant='h3'
        sx={{marginTop: '5px'}}
      >
        No insight selected
      </Typography>
    )
  }

  const noEvidenceListStyle = {
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    padding: '12px',
    gap: '8px',
    border: '1px solid #E4E5E7',
    borderRadius: '4px',
    flex: 'none',
    flexGrow: '0',
    justifyContent: 'center'
  }
  const evidenceListStyle = {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
    padding: '0px',
    gap: '8px',
    borderRadius: '0',
    flex: 'none',
    flexGrow: '0'
  }
  const evidenceListItemStyle = {
    flexDirection: 'column',
    padding: '12px',
    border: `1px solid ${colors.gray[100]}`,
    borderRadius: '4px',
    gap: '8px'
  }
  const evidenceTypeDateContainerStyle = {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '8px',
    justifyContent: 'space-between',
    color: colors.gray[600]
  }
  const evidenceIconDateStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    color: colors.gray[800],
    fontSize: '13px'
  }
  const evidenceNameStyle = {
    display: 'flex',
    width: '100%',
    lineHeight: '19px',
    fontWeight: '500'
  }
  const evidenceAuthorStyle = {
    display: 'flex',
    flexDirection: 'row',
    color: colors.gray[800],
    fontSize: '13px',
    lineHeight: '16px',
    gap: '8px',
    alignSelf: 'flex-start'
  }

  const evidenceListId = 'evidenceListId' + selectedInsight.id

  return (
    <Container
      id='supportingEvidencePane'
      data-testid='supportingEvidencePane'
      disableGutters
      sx={{border: '1px'}}
    >
      <InsightSupportingEvidenceHeader />

      <If
        condition={
          selectedInsight.supportingEvidence === null ||
          selectedInsight.supportingEvidence.length === 0
        }
      >
        <Then>
          <List sx={noEvidenceListStyle}>
            <ListItem sx={{justifyContent: 'center', padding: '0'}}>
              <MoxeIcon
                icon={cabinetFilingIcon}
                color={colors.gray[600]}
                fontSize={fontSizes.sm}
              />
            </ListItem>
            <ListItem
              id='noEvidenceHeader'
              data-testid='noEvidenceHeader'
              sx={{justifyContent: 'center', padding: '0'}}
            >
              No supporting evidence at this time
            </ListItem>
          </List>
        </Then>

        <Else>
          {(): any => (
            <List
              id={evidenceListId}
              data-testid={evidenceListId}
              key={evidenceListId}
              sx={evidenceListStyle}
            >
              {selectedInsight.supportingEvidence.map(
                (supportingEvidence: SupportingEvidence) => {
                  const evidenceListItemId =
                    'listItem' + supportingEvidence.codeName
                  const evidenceTypeDateGridContainerId =
                    'evidenceTypeDateGridContainer' +
                    supportingEvidence.codeName
                  const evidenceIconDateGridItemId =
                    'evidenceIconDateGridItem' + supportingEvidence.codeName
                  const evidenceNameGridItemId =
                    'evidenceNameGridItem' + supportingEvidence.codeName
                  const evidenceAuthorGridItemId =
                    'evidenceAuthorGridItem' + supportingEvidence.codeName
                  const evidenceLocationGridItemId =
                    'evidenceLocationGridItem' + supportingEvidence.codeName

                  return (
                    <ListItem
                      id={evidenceListItemId}
                      data-testid={evidenceListItemId}
                      key={evidenceListItemId}
                      sx={evidenceListItemStyle}
                    >
                      <Grid
                        container
                        id={evidenceTypeDateGridContainerId}
                        data-testid={evidenceTypeDateGridContainerId}
                        key={evidenceTypeDateGridContainerId}
                        sx={evidenceTypeDateContainerStyle}
                      >
                        <Grid
                          item
                          sx={{textTransform: 'uppercase', fontSize: '12px'}}
                        >
                          {supportingEvidence.evidenceTitle}
                        </Grid>
                        {supportingEvidence.dateOfService !== 'N/A' && (
                          <Grid
                            item
                            id={evidenceIconDateGridItemId}
                            data-testid={evidenceIconDateGridItemId}
                            key={evidenceIconDateGridItemId}
                            sx={evidenceIconDateStyle}
                          >
                            <Box>
                              <MoxeIcon
                                icon={calendarIcon}
                                color={colors.gray[600]}
                                fontSize={fontSizes.sm}
                              />
                            </Box>
                            <Box>{supportingEvidence.dateOfService}</Box>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          id={evidenceNameGridItemId}
                          data-testid={evidenceNameGridItemId}
                          key={evidenceNameGridItemId}
                          sx={evidenceNameStyle}
                        >
                          {supportingEvidence.code +
                            ' ' +
                            supportingEvidence.codeName}
                        </Grid>
                      </Grid>
                      {supportingEvidence.author !== 'N/A' && (
                        <Grid container>
                          <Grid
                            item
                            id={evidenceAuthorGridItemId}
                            data-testid={evidenceAuthorGridItemId}
                            key={evidenceAuthorGridItemId}
                            sx={evidenceAuthorStyle}
                          >
                            <Box>
                              <MoxeIcon
                                icon={userIcon}
                                color={colors.gray[600]}
                                fontSize={fontSizes.sm}
                              />
                            </Box>
                            <Box>{supportingEvidence.author}</Box>
                          </Grid>
                        </Grid>
                      )}
                      {supportingEvidence.location !== 'N/A' && (
                        <Grid container>
                          <Grid
                            item
                            id={evidenceLocationGridItemId}
                            data-testid={evidenceLocationGridItemId}
                            key={evidenceLocationGridItemId}
                            sx={evidenceAuthorStyle}
                          >
                            <Box>
                              <MoxeIcon
                                icon={hospitalIcon}
                                color={colors.gray[600]}
                                fontSize={fontSizes.sm}
                              />
                            </Box>
                            <Box>{supportingEvidence.location}</Box>
                          </Grid>
                        </Grid>
                      )}
                    </ListItem>
                  )
                }
              )}
            </List>
          )}
        </Else>
      </If>
    </Container>
  )
}
