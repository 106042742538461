// React
import {useContext, useEffect, useState} from 'react'

// Material UI
import {Box, Snackbar} from '@mui/material'

// Font Awesome
import {faTimes} from '@fortawesome/pro-regular-svg-icons'

// Components
import {MoxeIcon} from '@moxe/component-library'

// Context
import {SnackbarContext} from './context/SnackbarContext'

// CSS
import {colors} from './css/colors'
import {fontWeights} from './css/fontWeights'

export const MoxeSnackbar = () => {
  const {snackbarOptions, setSnackbarOptions} = useContext(SnackbarContext)
  const [snackbarStyle, setSnackbarStyle] = useState<object>({})

  useEffect(() => {
    const baseSnackbarStyle = {
      display: 'inline-flex',
      padding: '12px 16px',
      borderRadius: '5px',
      height: '16px',
      lineHeight: '16px'
    }
    switch (snackbarOptions.snackbarVariant) {
      case 'error':
        setSnackbarStyle({
          ...baseSnackbarStyle,
          border: `1px solid ${colors.red[100]}`,
          background: colors.red[50],
          color: colors.red[600]
        })
        break
      case 'default':
        setSnackbarStyle({
          ...baseSnackbarStyle,
          background: '#0C1234',
          color: '#FFFFFF',
          fontWeight: fontWeights.sm
        })
    }
  }, [snackbarOptions.snackbarVariant])

  return (
    <Snackbar
      id='moxeSnackbar'
      data-testid='moxeSnackbar'
      open={snackbarOptions.isSnackbarOpen}
      autoHideDuration={5000}
      onClose={() =>
        setSnackbarOptions({...snackbarOptions, isSnackbarOpen: false})
      }
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
    >
      <Box
        id='moxeSnackbarContent'
        data-testid='moxeSnackbarContent'
        sx={snackbarStyle}
      >
        {snackbarOptions.snackbarChildComponent}
        <Box
          id='SnackbarCloseIconContainer'
          data-testid='SnackbarCloseIconContainer'
          onClick={() =>
            setSnackbarOptions({...snackbarOptions, isSnackbarOpen: false})
          }
          sx={{display: 'inline-flex', marginLeft: '16px'}}
        >
          <MoxeIcon icon={faTimes} color={colors.gray[600]} fontSize='16px' />
        </Box>
      </Box>
    </Snackbar>
  )
}
