import {colors} from '../colors'
import {fontWeights} from '../fontWeights'

export const insightContainerStyle = {
  marginBottom: '8px',
  border: `1px solid ${colors.gray[300]}`,
  borderRadius: '4px'
}

export const insightHeaderStyle = {
  padding: '12px',
  background: colors.gray[50],
  color: colors.gray[1000],
  borderRadius: '4px'
}

export const insightNameStyle = {
  height: '16px',
  lineHeight: '16px',
  fontSize: '16px',
  fontWeight: fontWeights.lg
}

export const noteContainerStyle = {
  display: 'inline-flex',
  padding: '12px 12px'
}

export const noteLabelStyle = {
  marginRight: '8px',
  fontWeight: fontWeights.md,
  color: colors.gray[1000]
}
