import {Box, Grid, Skeleton} from '@mui/material'
import {colors} from '../../css/colors'

export const InsightFormLoading = () => {
  const formHeaderStyle = {
    paddingBottom: '21px',
    marginBottom: '16px',
    borderBottom: `1px solid ${colors.gray[300]}`
  }

  const categorySkeletonStyle = {
    width: '100px',
    height: '16px',
    marginBottom: '8px',
    borderRadius: '4px',
    backgroundColor: '#F0F0F0'
  }

  const insightNameSkeletonStyle = {
    width: '175px',
    height: '24px',
    borderRadius: '4px',
    backgroundColor: '#F0F0F0'
  }

  const numberOfTotalBoxStyle = {
    float: 'right',
    width: '72px',
    height: '16px',
    padding: '4px 12px',
    borderRadius: '3px',
    backgroundColor: colors.gray[50]
  }

  const numberOfTotalSkeletonStyle = {
    borderRadius: '2px',
    backgroundColor: '#DFDFDF'
  }

  const formContentSkeletonStyle = {
    width: '400px',
    height: '48px',
    borderRadius: '4px',
    backgroundColor: '#F0F0F0'
  }

  return (
    <Box id='insightFormLoading' data-testid='insightFormLoading'>
      <Grid container spacing={0} sx={formHeaderStyle}>
        <Grid item xs={9.5}>
          <Skeleton
            animation='wave'
            variant='rectangular'
            sx={categorySkeletonStyle}
          />
          <Skeleton
            animation='wave'
            variant='rectangular'
            sx={insightNameSkeletonStyle}
          />
        </Grid>

        <Grid item xs={2.5}>
          <Box sx={numberOfTotalBoxStyle}>
            <Skeleton
              animation='wave'
              variant='rectangular'
              sx={numberOfTotalSkeletonStyle}
            />
          </Box>
        </Grid>
      </Grid>

      <Skeleton
        animation='wave'
        variant='rectangular'
        sx={categorySkeletonStyle}
      />
      <Skeleton
        animation='wave'
        variant='rectangular'
        sx={formContentSkeletonStyle}
      />
    </Box>
  )
}
