// React
import {useContext, useEffect, useState} from 'react'

// Material UI
import {Box} from '@mui/system'

// Font Awesome
import {faCommentExclamation} from '@fortawesome/pro-regular-svg-icons'

// Components
import {MoxeIcon} from '@moxe/component-library'

// Context
import {SelectedInsightContext} from '../../../context/SelectedInsightContext'

// Types
import {InsightCategoryEnum} from '../../../types/state/InsightCategoryEnum'

// CSS
import {colors} from '../../../css/colors'
import {fontWeights} from '../../../css/fontWeights'

// This component assumes there will for sure be a message to display
export const ValidationError = () => {
  const {selectedInsight} = useContext(SelectedInsightContext)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    const validation = selectedInsight.validation
    if (selectedInsight.category !== InsightCategoryEnum.QualityMeasures) {
      if (!validation.isActionValid || !validation.isSelectedDiagnosisValid) {
        if (selectedInsight.recommendedDiagnosis !== null) {
          setErrorMessage(
            'Please select a diagnosis code or reject the condition'
          )
        } else {
          setErrorMessage('Please add a diagnosis code or reject it')
        }
      }
    } else {
      if (!validation.isActionValid) {
        setErrorMessage(
          'Please address the quality measure or select Not needed'
        )
      }
    }
  }, [])

  const errorBoxStyle = {
    marginBottom: '16px',
    padding: '8px 14px',
    height: '16px',
    lineHeight: '16px',
    fontWeight: fontWeights.sm,
    color: colors.red[400],
    background: colors.red[50],
    border: `1px solid ${colors.red[100]}`,
    borderRadius: '4px'
  }

  const errorMessageStyle = {
    display: 'inline-flex',
    marginLeft: '10px'
  }

  const errorBoxId = 'validationErrorBox' + selectedInsight.id
  const errorMessageId = 'validationErrorMessage' + selectedInsight.id

  return (
    <Box id={errorBoxId} data-testid={errorBoxId} sx={errorBoxStyle}>
      <MoxeIcon
        icon={faCommentExclamation}
        color={colors.red[400]}
        fontSize='16px'
      />
      <Box
        id={errorMessageId}
        data-testid={errorMessageId}
        sx={errorMessageStyle}
      >
        {errorMessage}
      </Box>
    </Box>
  )
}
