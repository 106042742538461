import React from 'react'
import {SnackbarContextState} from '../types/state/SnackbarContextState'

export const SnackbarContext = React.createContext<SnackbarContextState>({
  snackbarOptions: {
    isSnackbarOpen: false,
    snackbarVariant: null,
    snackbarChildComponent: ''
  },
  setSnackbarOptions: () => {}
})
