// Material UI
import {Box} from '@mui/material'
import {colors} from '../../css/colors'
import {fontWeights} from '../../css/fontWeights'

export const RequiredAsterisk = () => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        color: colors.red[500],
        fontWeight: fontWeights.xl
      }}
    >
      *
    </Box>
  )
}
