// React
import {useContext} from 'react'

// Material UI
import {Box, ListItemButton} from '@mui/material'

// Font Awesome
import {
  faCheck,
  faTimes,
  faFileTimes,
  faEye
} from '@fortawesome/pro-regular-svg-icons'

// Components
import {MoxeIcon} from '@moxe/component-library'

// Context
import {ConfigurationContext} from '../../../context/ConfigurationContext'
import {SelectedInsightContext} from '../../../context/SelectedInsightContext'
import {SubmissionScreenContext} from '../../../context/SubmissionScreenContext'

// Constants
import {constants} from '../../../constants/constants'

// Types
import {InsightStatusEnum} from '../../../types/state/InsightStatusEnum'
import {InsightListItemButtonProps} from '../../../types/props/InsightListItemButtonProps'
import {Insight} from '../../../types/state/Insight'
import {InsightCategoryEnum} from '../../../types/state/InsightCategoryEnum'

// CSS
import {colors} from '@moxe/design-tokens'
import {listItemLabelStyle} from '../../../css/styles/listItemLabelStyle'
import {fontSizes} from '../../../css/fontSizes'
import {fontWeights} from '../../../css/fontWeights'

// Utils
import {isInsightIncomplete} from '../../../utils/isInsightIncomplete'
import {formatDiagnosisForDisplay} from '../../../utils/formatDiagnosisForDisplay'
import {ActionEnum} from '../../../types/state/ActionEnum'

export const InsightListItemButton = (props: InsightListItemButtonProps) => {
  const {insight} = props
  const {userRole} = useContext(ConfigurationContext)
  const {selectedInsight, updateSelectedInsight, updateInsight} = useContext(
    SelectedInsightContext
  )
  const {shouldBeOnSubmissionScreen, setShouldBeOnSubmissionScreen} =
    useContext(SubmissionScreenContext)

  const clickInsight = (insight: Insight) => {
    if (insight.id !== selectedInsight.id) {
      if (isInsightIncomplete(selectedInsight)) {
        let selectedInsightCopy = {...selectedInsight}
        selectedInsightCopy.status = InsightStatusEnum.Incomplete
        updateInsight(selectedInsightCopy)
      }
      updateSelectedInsight(insight)
    }
    setShouldBeOnSubmissionScreen(false)
  }

  const tooltipStyle = {
    '&:after': {
      opacity: 0,
      top: '-1em',
      content: 'attr(data-title)',
      position: 'absolute',
      left: '2em',
      width: 'auto',
      alignText: 'center',
      alignItems: 'middle',
      display: 'flex',
      padding: '4px 8px',
      color: colors.black,
      backgroundColor: colors.gray[100],
      border: `1px solid ${colors.gray[500]}`,
      borderRadius: '4px',
      whiteSpace: 'nowrap',
      boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
      zIndex: '99999',
      pointerEvents: 'none',
      fontSize: fontSizes.md,
      fontWeight: fontWeights.md
    },
    '&:hover:after': {
      opacity: 1,
      transition: 'opacity 200ms ease-in 2000ms'
    }
  }

  const insightItemButtonStyle = {
    lineHeight: '16.41px',
    marginBottom: '4px',
    padding: '11px 16px',
    background: colors.white,
    border: 1,
    borderRadius: '4px',
    borderColor: colors.gray[200],
    justifyContent: 'space-between',
    '&:hover': {
      border: `1px solid ${colors.gray[400]}`,
      backgroundColor: colors.white
    },
    '&:focus': {
      border: `1px solid ${colors.orange[500]}`,
      backgroundColor: colors.white
    },
    '&:active': {
      border: `1px solid ${colors.gray[400]}`,
      backgroundColor: colors.white
    },
    '&.Mui-selected': {
      border: `1px solid ${colors.orange[300]}`,
      backgroundColor: colors.orange[50]
    },
    '&:focus&.Mui-selected, &:hover&.Mui-selected': {
      backgroundColor: colors.orange[50]
    },
    ...tooltipStyle
  }

  const insightNameStyle = {
    ...listItemLabelStyle,
    display: 'block',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: 'auto',
    width: '100%'
  }

  const defaultStatusLabelStyle = {
    display: 'block',
    marginTop: '4px',
    fontWeight: fontWeights.sm,
    fontSize: fontSizes.sm,
    color: colors.gray[700]
  }

  let statusIcon,
    statusColorGroup,
    statusLabelColor,
    statusLabelLeftText,
    statusLabelRightText
  const isQualityInsight =
    insight.category === InsightCategoryEnum.QualityMeasures
  switch (insight.status) {
    case InsightStatusEnum.Accepted:
      statusIcon = faCheck
      statusColorGroup = colors.green
      if (!isQualityInsight) {
        statusLabelColor = colors.gray[700]
        statusLabelLeftText =
          insight.selectedDiagnosisSaved &&
          formatDiagnosisForDisplay(insight.selectedDiagnosisSaved)
      } else {
        statusLabelColor = statusColorGroup[500]
        statusLabelLeftText = 'Addressed'
        statusLabelRightText = insight.noteSaved
      }
      break
    case InsightStatusEnum.Rejected:
      statusIcon = faTimes
      statusColorGroup = colors.red
      statusLabelColor = statusColorGroup[500]
      if (!isQualityInsight) {
        statusLabelLeftText = InsightStatusEnum.Rejected
        statusLabelRightText = insight.reasonSaved
      } else {
        statusLabelLeftText = constants.QUALITY_REJECTION_STATUS_LABEL
        statusLabelRightText = insight.reasonSaved
      }
      break
    case InsightStatusEnum.Incomplete:
      if (userRole === 'Clinician') {
        if (
          !insight.isConfirmed &&
          insight.actionSaved === insight.actionInput &&
          insight.selectedDiagnosisSaved === insight.selectedDiagnosis &&
          insight.reasonSaved === insight.reasonInput &&
          insight.noteSaved === insight.noteInput
        ) {
          statusIcon = faEye
          statusColorGroup = colors.blue
          statusLabelColor = statusColorGroup[500]
          statusLabelLeftText = 'Reviewed previously'
          statusLabelRightText = null
        } else {
          statusIcon = faFileTimes
          statusColorGroup = colors.yellow
          statusLabelColor = statusColorGroup[500]
          statusLabelLeftText = InsightStatusEnum.Incomplete
          statusLabelRightText =
            insight.selectedDiagnosis !== null
              ? 'No condition status selected'
              : ''
        }
        break
      } else {
        if (userRole === 'NonClinician') {
          if (!isQualityInsight) {
            if (
              insight.selectedDiagnosisSaved !== null &&
              insight.actionSaved === ActionEnum.Accepted
            ) {
              statusIcon = faCheck
              statusColorGroup = colors.green
              statusLabelColor = colors.gray[700]
              statusLabelLeftText =
                insight.selectedDiagnosisSaved &&
                formatDiagnosisForDisplay(insight.selectedDiagnosisSaved)
            } else {
              statusIcon = faFileTimes
              statusColorGroup = colors.yellow
              statusLabelColor = statusColorGroup[500]
              statusLabelLeftText = InsightStatusEnum.Incomplete
              statusLabelRightText =
                insight.selectedDiagnosis !== null ? 'Incomplete' : ''
            }
          } else {
            if (insight.actionSaved === ActionEnum.Accepted) {
              statusIcon = faCheck
              statusColorGroup = colors.green
              statusLabelColor = statusColorGroup[500]
              statusLabelLeftText = 'Addressed'
              statusLabelRightText = insight.noteSaved
            } else {
              statusIcon = faFileTimes
              statusColorGroup = colors.yellow
              statusLabelColor = statusColorGroup[500]
              statusLabelLeftText = InsightStatusEnum.Incomplete
              statusLabelRightText = null
            }
          }
          break
        }
      }
  }

  const insightItemButtonId = 'insightButton' + insight.name
  const insightStatusLabelId = 'insightStatusLabelColored' + insight.id

  return (
    <ListItemButton
      data-title={insight.name}
      id={insightItemButtonId}
      data-testid={insightItemButtonId}
      disableRipple
      sx={insightItemButtonStyle}
      onClick={() => clickInsight(insight)}
      selected={
        !shouldBeOnSubmissionScreen && insight.id === selectedInsight.id
      }
    >
      <Box
        sx={{
          display: 'block',
          marginRight: '7px',
          width: 'calc(100% - 34px)'
        }}
      >
        <Box sx={insightNameStyle}>{insight.name}</Box>
        {insight.status !== InsightStatusEnum.NotAddressed && (
          <Box
            id={'insightStatusLabel' + insight.id}
            data-testid={'insightStatusLabel' + insight.id}
            sx={defaultStatusLabelStyle}
          >
            <Box
              id={insightStatusLabelId}
              data-testid={insightStatusLabelId}
              sx={{display: 'inline-flex', color: statusLabelColor}}
            >
              {statusLabelLeftText}
            </Box>
            {statusLabelRightText && ' - ' + statusLabelRightText}
          </Box>
        )}
      </Box>
      {statusIcon && statusColorGroup && (
        <Box
          id={'insightStatusIconContainer' + insight.status + insight.id}
          data-testid={
            'insightStatusIconContainer' + insight.status + insight.id
          }
        >
          <MoxeIcon
            icon={statusIcon}
            color={statusColorGroup[500]}
            fontSize='20px'
          />
        </Box>
      )}
    </ListItemButton>
  )
}
