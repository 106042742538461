// React
import {useContext} from 'react'
import {Else, If, Then} from 'react-if'

// Material UI
import {Box} from '@mui/material'

// Context
import {StatusSectionContext} from '../../../context/StatusSectionContext'

// Types
import {InsightCategoryEnum} from '../../../types/state/InsightCategoryEnum'

// CSS
import {fontWeights} from '../../../css/fontWeights'
import {colors} from '../../../css/colors'
import {
  insightContainerStyle,
  insightHeaderStyle,
  insightNameStyle,
  noteContainerStyle,
  noteLabelStyle
} from '../../../css/styles/summaryInsightStyle'

// Utils
import {formatDiagnosisForDisplay} from '../../../utils/formatDiagnosisForDisplay'

export const UnsavedStatusSectionContent = () => {
  const {statusGroup, areNotesDisplayed} = useContext(StatusSectionContext)

  const insightHeaderNotAsContainerStyle = {
    ...insightHeaderStyle,
    borderRadius: '4px 4px 0px 0px',
    borderBottom: `1px solid ${colors.gray[300]}`
  }

  const insightHelperTextStyle = {
    marginTop: '8px',
    fontWeight: fontWeights.sm
  }

  const diagnosisStyle = {
    display: 'inline-flex',
    marginRight: '8px',
    color: colors.gray[900],
    fontWeight: fontWeights.lg
  }

  return (
    <Box id='unsavedStatusSection' data-testid='unsavedStatusSection'>
      {statusGroup.insights.map((insight) => {
        const baseId = 'unsavedStatusSection'
        const insightContainerId = baseId + 'InsightContainer' + insight.name
        const insightNameId = baseId + 'InsightName' + insight.name
        const insightHelperTextId = baseId + 'InsightHelperText' + insight.name
        const insightDiagnosisId = baseId + 'InsightDiagnosis' + insight.name
        const insightNoteContainerId =
          baseId + 'InsightNoteContainer' + insight.name
        const insightNoteId = baseId + 'InsightNote' + insight.name
        return (
          <Box
            id={insightContainerId}
            data-testid={insightContainerId}
            sx={insightContainerStyle}
            key={insight.id}
          >
            <If
              condition={
                insight.category !== InsightCategoryEnum.QualityMeasures
              }
            >
              <Then>
                <Box sx={insightHeaderNotAsContainerStyle}>
                  <Box
                    id={insightNameId}
                    data-testid={insightNameId}
                    sx={insightNameStyle}
                  >
                    {insight.name}
                  </Box>
                  <Box
                    id={insightHelperTextId}
                    data-testid={insightHelperTextId}
                    sx={insightHelperTextStyle}
                  >
                    These have unsaved changes. Return to the insight to save
                    changes.
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: '8px 12px',
                    borderBottom:
                      areNotesDisplayed && insight.noteSaved
                        ? `1px solid ${colors.gray[50]}`
                        : 'none'
                  }}
                >
                  <Box
                    id={insightDiagnosisId}
                    data-testid={insightDiagnosisId}
                    sx={diagnosisStyle}
                  >
                    {insight.selectedDiagnosisSaved
                      ? formatDiagnosisForDisplay(
                          insight.selectedDiagnosisSaved
                        )
                      : 'Action unsaved'}
                  </Box>
                </Box>
              </Then>
              <Else>
                <Box
                  sx={
                    areNotesDisplayed && insight.noteSaved
                      ? insightHeaderNotAsContainerStyle
                      : insightHeaderStyle
                  }
                >
                  <Box
                    id={insightNameId}
                    data-testid={insightNameId}
                    sx={insightNameStyle}
                  >
                    {insight.name}
                  </Box>
                </Box>
              </Else>
            </If>

            <If condition={areNotesDisplayed && insight.noteSaved}>
              <Then>
                <Box
                  id={insightNoteContainerId}
                  data-testid={insightNoteContainerId}
                  sx={noteContainerStyle}
                >
                  <Box sx={noteLabelStyle}>
                    {insight.category !== InsightCategoryEnum.QualityMeasures
                      ? 'Note:'
                      : 'Addressed:'}
                  </Box>
                  <Box
                    id={insightNoteId}
                    data-testid={insightNoteId}
                    sx={{color: colors.gray[700]}}
                  >
                    {insight.noteSaved}
                  </Box>
                </Box>
              </Then>
            </If>
          </Box>
        )
      })}
    </Box>
  )
}
