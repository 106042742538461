export const getAuthorizeEndpoint = (): string =>
  window.location.pathname + window.location.search

export const getInsightsEndpoint = (): string => '/v3/insights'

export const getConfigurationEndpoint = (): string => '/v1/configuration'

export const getEncounterDetailsEndpoint = (): string => '/v1/encounter-details'

export const getInsightFormSubmissionEndpoint = (): string =>
  '/v3/insight-form-submission'

export const getSubmitForReviewEndpoint = (): string => '/v3/submit-for-review'

export const getReviewAndSignEndpoint = (): string => '/v3/signature'
