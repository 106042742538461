import React from 'react'
import {InsightStatusEnum} from '../types/state/InsightStatusEnum'
import {StatusGroupEnum} from '../types/state/StatusGroupEnum'
import {SelectedInsightContextState} from '../types/state/SelectedInsightContextState'
import {defaultInsightValidation} from '../utils/validation'

export const SelectedInsightContext =
  React.createContext<SelectedInsightContextState>({
    selectedInsight: {
      id: '',
      name: '',
      category: '',
      order: 0,
      supportingEvidence: [],
      diagnoses: [],
      recommendedDiagnosis: null,
      isEditMode: false,
      validation: defaultInsightValidation(),
      previousDiagnosis: null,
      previousAction: null,
      previousReason: null,
      previousNote: null,
      actionInput: null,
      reasonInput: null,
      noteInput: null,
      selectedDiagnosis: null,
      status: InsightStatusEnum.NotAddressed,
      summaryScreenStatus: StatusGroupEnum.NotAddressed,
      actionSaved: null,
      reasonSaved: null,
      noteSaved: null,
      selectedDiagnosisSaved: null,
      isConfirmed: false
    },
    updateSelectedInsight: () => {},
    updateInsight: () => {}
  })
