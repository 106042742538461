// Material UI
import {Box, List, ListItem} from '@mui/material'

// CSS
import {colors} from '../../css/colors'
import {fontSizes} from '../../css/fontSizes'

// Components
import {MoxeIcon} from '@moxe/component-library'

// Font Awesome
import {faExclamationTriangle} from '@fortawesome/pro-regular-svg-icons'

export const InsightFormEmpty = () => {
  const noInsightContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
  }

  const noInsightListStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0',
    padding: '24px',
    gap: '8px',
    border: '1px solid #E4E5E7',
    borderRadius: '4px',
    flex: 'none',
    flexGrow: '1',
    justifyContent: 'center'
  }

  return (
    <Box
      id='insightFormEmpty'
      data-testid='insightFormEmpty'
      sx={noInsightContainerStyle}
    >
      <List sx={noInsightListStyle}>
        <ListItem sx={{justifyContent: 'center', padding: '0'}}>
          <MoxeIcon
            icon={faExclamationTriangle}
            color={colors.gray[600]}
            fontSize={fontSizes.sm}
          />
        </ListItem>
        <ListItem
          id='noInsightHeader'
          data-testid='noInsightHeader'
          sx={{justifyContent: 'center', padding: '0'}}
        >
          No conditions or quality measures need further work
        </ListItem>
      </List>
    </Box>
  )
}
