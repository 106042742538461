// React
import {useContext} from 'react'
import {Else, If, Then} from 'react-if'

// Material UI
import {Box} from '@mui/material'

// Components
import {MoxeTextField} from '../../reusable/MoxeTextField'
import {CommentToProviderLabelTooltip} from './CommentToProviderLabelTooltip'

// Context
import {ConfigurationContext} from '../../../context/ConfigurationContext'
import {EncounterDetailsContext} from '../../../context/EncounterDetailsContext'

// Types
import {PatientDetails} from '../../../types/state/PatientDetails'

// CSS
import {patientDetailsSectionBaseLabelStyle} from '../../../css/styles/patientDetailsSectionBaseLabelStyle'

// Utils
import {hasPermission} from '../../../utils/hasPermission'

export const EncounterDetailsContent = (props: PatientDetails) => {
  let {patientName, patientDOB, patientMRN} = props
  const {userPermissions} = useContext(ConfigurationContext)
  const {commentToProvider, setCommentToProvider} = useContext(
    EncounterDetailsContext
  )

  const hasEditCommentPermission = hasPermission(
    userPermissions,
    'EDIT_COMMENT_TO_PROVIDER'
  )

  const patientDetailItemBaseStyle = {
    display: 'inline-block',
    marginBottom: '12px'
  }

  const patientDetailItemWithPaddingStyle = {
    ...patientDetailItemBaseStyle,
    paddingRight: '32px'
  }

  const patientDetailTextStyle = {
    height: '21px'
  }

  const patientDetailLabelStyle = {
    ...patientDetailTextStyle,
    ...patientDetailsSectionBaseLabelStyle
  }

  const encounterDetailsContentId = 'encounterDetailsContent'
  const patientNameItemId = 'patientNameItem'
  const patientDobItemId = 'patientDobItem'
  const patientMrnItemId = 'patientMrnItem'
  const commentToProviderFeatureId = 'commentToProviderFeature'
  const commentToProviderWithoutInputId = 'commentToProviderWithoutInput'

  return (
    <Box id={encounterDetailsContentId} data-testid={encounterDetailsContentId}>
      <Box display='block'>
        <Box
          id={patientNameItemId}
          data-testid={patientNameItemId}
          sx={patientDetailItemWithPaddingStyle}
        >
          <Box sx={patientDetailLabelStyle}>Name</Box>
          <Box sx={patientDetailTextStyle}>{patientName}</Box>
        </Box>
        <Box
          id={patientDobItemId}
          data-testid={patientDobItemId}
          sx={patientDetailItemWithPaddingStyle}
        >
          <Box sx={patientDetailLabelStyle}>DOB</Box>
          <Box sx={patientDetailTextStyle}>{patientDOB}</Box>
        </Box>
        <Box
          id={patientMrnItemId}
          data-testid={patientMrnItemId}
          sx={patientDetailItemBaseStyle}
        >
          <Box sx={patientDetailLabelStyle}>MRN</Box>
          <Box sx={patientDetailTextStyle}>{patientMRN}</Box>
        </Box>
      </Box>

      <If
        condition={
          hasEditCommentPermission === true || commentToProvider !== null
        }
      >
        <Then>
          <Box
            id={commentToProviderFeatureId}
            data-testid={commentToProviderFeatureId}
            sx={{marginBottom: '12px'}}
          >
            <CommentToProviderLabelTooltip />
            <If condition={!hasEditCommentPermission}>
              <Then>
                <Box
                  id={commentToProviderWithoutInputId}
                  data-testid={commentToProviderWithoutInputId}
                >
                  {commentToProvider}
                </Box>
              </Then>
              <Else>
                <MoxeTextField
                  textFieldId='CommentToProviderTextField'
                  text={commentToProvider}
                  rows={3}
                  onChange={(event) => setCommentToProvider(event.target.value)}
                />
              </Else>
            </If>
          </Box>
        </Then>
      </If>
    </Box>
  )
}
