// React
import {useContext} from 'react'

// Material UI
import {Box, Tooltip, tooltipClasses} from '@mui/material'

// Font Awesome
import {faQuestionCircle} from '@fortawesome/pro-light-svg-icons'

// Context
import {ConfigurationContext} from '../../../context/ConfigurationContext'

// Components
import {MoxeIcon} from '@moxe/component-library'

// CSS
import {colors} from '@moxe/design-tokens'
import {fontSizes} from '../../../css/fontSizes'
import {patientDetailsSectionBaseLabelStyle} from '../../../css/styles/patientDetailsSectionBaseLabelStyle'

// Utils
import {hasPermission} from '../../../utils/hasPermission'

export const CommentToProviderLabelTooltip = () => {
  const {userPermissions} = useContext(ConfigurationContext)

  const commentToProviderLabelContainerStyle = {
    margin: '4px 0px',
    display: 'inline-flex',
    height: '16px',
    lineHeight: '16px'
  }

  const commentToProviderLabelStyle = {
    ...patientDetailsSectionBaseLabelStyle,
    display: 'inline-block',
    marginRight: '4px'
  }

  let tooltipTitle = 'This comment will not file to legal medical record'
  if (hasPermission(userPermissions, 'EDIT_COMMENT_TO_PROVIDER'))
    tooltipTitle +=
      '; it will only be forwarded to the provider if there are insights for the provider to assess.'

  const commentToProviderContainerId = 'commentToProviderContainer'
  const commentToProviderLabelId = 'commentToProviderLabel'
  const questionCircleIconId = 'commentToProviderQuestionCircle'

  return (
    <Tooltip
      title={tooltipTitle}
      placement='top-start'
      arrow
      componentsProps={{
        popper: {
          sx: {
            [`& .${tooltipClasses.arrow}`]: {
              color: colors.blue[900]
            },
            [`&& .${tooltipClasses.tooltip}`]: {
              backgroundColor: colors.blue[900],
              color: colors.gray[0],
              maxWidth: '350px',
              padding: '8px',
              marginBottom: '10px',
              fontSize: fontSizes.md
            }
          }
        }
      }}
    >
      <Box
        id={commentToProviderContainerId}
        data-testid={commentToProviderContainerId}
        sx={commentToProviderLabelContainerStyle}
      >
        <Box
          id={commentToProviderLabelId}
          data-testid={commentToProviderLabelId}
          sx={commentToProviderLabelStyle}
        >
          Comment to Provider
        </Box>
        <Box
          id={questionCircleIconId}
          data-testid={questionCircleIconId}
          display='inline-block'
        >
          <MoxeIcon
            icon={faQuestionCircle}
            color='#322E2D'
            fontSize={fontSizes.sm}
          />
        </Box>
      </Box>
    </Tooltip>
  )
}
