import {Typography} from '@mui/material'

export const InsightSupportingEvidenceHeader = (props: {
  isHeaderForLoadingScreen?: boolean
  isHeaderForEmptyScreen?: boolean
}) => {
  const evidenceHeaderStyle = {
    display: 'block',
    fontSize: '14px',
    fontWeight: '700',
    marginBottom: '16px',
    lineHeight: '16px',
    letterSpacing: '0',
    textTransform: 'none',
    height: '24px',
    gap: '8px',
    borderBottom: '1px solid #E4E5E7',
    alignSelf: 'stretch'
  }

  let id = 'evidenceHeader'
  if (props.isHeaderForLoadingScreen) id += 'Loading'
  else if (props.isHeaderForEmptyScreen) id += 'Empty'

  return (
    <Typography
      id={id}
      data-testid={id}
      variant='overline'
      sx={evidenceHeaderStyle}
    >
      Supporting Evidence
    </Typography>
  )
}
