// Material UI
import {Box, FormControlLabel, Radio} from '@mui/material'

// Font Awesome
import {faChevronUp, faChevronDown} from '@fortawesome/pro-regular-svg-icons'

// Components
import {MoxeIcon} from '@moxe/component-library'

// Types
import {RadioOptionProps} from '../../types/props/RadioOptionProps'

// CSS
import {colors} from '../../css/colors'
import {fontWeights} from '../../css/fontWeights'

export const RadioOption = (props: RadioOptionProps) => {
  const {
    optionId,
    text,
    onChange,
    isSelected,
    isDisabled,
    shouldRemoveIcons,
    customStyle
  } = props

  const id = 'radioOption' + optionId

  const getRadioOptionLabel = () => {
    if (shouldRemoveIcons) {
      return text
    } else {
      let iconProp, iconId
      if (isSelected) {
        iconProp = faChevronUp
        iconId = `chevronUp${optionId}`
      } else {
        iconProp = faChevronDown
        iconId = `chevronDown${optionId}`
      }

      return (
        <Box sx={{display: 'inline-flex', width: '100%', paddingRight: '12px'}}>
          <Box
            sx={{
              width: '100%',
              color: isDisabled ? colors.gray[600] : colors.gray[900]
            }}
          >
            {text}
          </Box>
          <Box id={iconId + 'Container'} data-testid={iconId + 'Container'}>
            <MoxeIcon
              icon={iconProp}
              color={isDisabled ? colors.gray[200] : colors.gray[500]}
              fontSize='16px'
            />
          </Box>
        </Box>
      )
    }
  }

  const radioOptionStyle = {
    width: '100%',
    margin: '0',
    borderRadius: '2px',
    fontWeight: fontWeights.sm,
    '&& .MuiRadio-root': {
      marginLeft: '3px',
      color: isSelected ? colors.orange[500] : colors.gray[400],
      '&:hover': {
        background: 'none'
      }
    },
    ...customStyle
  }

  return (
    <FormControlLabel
      disableTypography
      id={id}
      data-testid={id}
      value={text}
      control={<Radio size='small' />}
      label={getRadioOptionLabel()}
      sx={radioOptionStyle}
      onChange={onChange}
      checked={isSelected}
      disabled={isDisabled}
    />
  )
}
