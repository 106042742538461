import {MoxeTextFieldProps} from '../../types/props/MoxeTextFieldProps'

//MUI
import {TextField} from '@mui/material'
import {colors} from '../../css/colors'

export const MoxeTextField = (props: MoxeTextFieldProps) => {
  const {textFieldId, text, placeholderText, rows, onChange, onBlur} = props

  const textFieldStyle = {
    '& .MuiInputBase-input': {
      fontSize: '14px',
      lineHeight: '21px',
      color: colors.gray[900]
    },
    '& .MuiInputBase-root': {
      padding: '8px 12px',
      backgroundColor: colors.gray[0]
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.gray[400]}`
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.gray[700]}`
    },
    '&& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.gray[900]}`
    }
  }

  const id = 'moxeTextField' + textFieldId
  const textToDisplay = text ? text : ''
  const rowsToDisplay = rows ? rows : 4

  return (
    <TextField
      fullWidth
      multiline
      rows={rowsToDisplay}
      id={id}
      data-testid={id}
      sx={textFieldStyle}
      value={textToDisplay}
      placeholder={placeholderText}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}
