// React
import {useContext} from 'react'

// Material UI
import {Container, RadioGroup, Accordion, AccordionDetails} from '@mui/material'

// Context
import {ConfigurationContext} from '../../../../context/ConfigurationContext'
import {SelectedInsightContext} from '../../../../context/SelectedInsightContext'

// Components
import {RequiredLabel} from '../../../reusable/RequiredLabel'
import {RadioOption} from '../../../reusable/RadioOption'
import {CommonLabel} from '../../../reusable/CommonLabel'
import {MoxeTextField} from '../../../reusable/MoxeTextField'

// Types
import {ConditionApprovalReason} from '../../../../types/state/ConditionApprovalReason'
import {StatusTreatmentPlanMapping} from '../../../../types/state/StatusTreatmentPlanMapping'
import {ActionEnum} from '../../../../types/state/ActionEnum'

// CSS
import {radioOptionStyle} from '../../../../css/styles/radioOptionStyle'
import {colors} from '../../../../css/colors'

// Utils
import {defaultInsightValidation} from '../../../../utils/validation'
import {hasPermission} from '../../../../utils/hasPermission'

export const StatusForm = () => {
  const {selectedInsight, updateInsight} = useContext(SelectedInsightContext)
  const {userPermissions} = useContext(ConfigurationContext)

  const clickStatus = (
    reasonInput: ConditionApprovalReason,
    noteInput: string
  ) => {
    let selectedInsightCopy = {...selectedInsight}
    selectedInsightCopy.reasonInput = reasonInput
    selectedInsightCopy.noteInput = noteInput
    selectedInsightCopy.validation = defaultInsightValidation()
    updateInsight(selectedInsightCopy)
  }

  const handleNoteOnChange = (newValue: string) => {
    let selectedInsightCopy = {...selectedInsight}
    selectedInsightCopy.noteInput = newValue
    updateInsight(selectedInsightCopy)
  }

  const handleNoteOnBlur = (event: any) => {
    let placeholderText = event.target.placeholder
    let value = event.target.value
    if (value === '') {
      let selectedInsightCopy = {...selectedInsight}
      selectedInsightCopy.noteInput = placeholderText
      updateInsight(selectedInsightCopy)
    }
  }

  const hasConditionStatusPermission = hasPermission(
    userPermissions,
    'EDIT_CONDITION_STATUS_AND_NOTE'
  )

  const getAccordionStyle = (isOptionSelected: boolean) => {
    return {
      width: '100%',
      marginBottom: '4px',
      '&:before': {
        display: 'none'
      },
      boxShadow: 'none',
      border: `1px solid ${
        selectedInsight.selectedDiagnosis && hasConditionStatusPermission
          ? colors.gray[300]
          : colors.gray[100]
      }`,
      '&.MuiAccordion-root': {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        borderRadius: '2px'
      },
      backgroundColor:
        isOptionSelected && selectedInsight.selectedDiagnosis
          ? colors.gray[50]
          : colors.gray[0]
    }
  }

  const conditionReasons: ConditionApprovalReason[] = [
    'Stable',
    'Improved',
    'Worsening'
  ]

  const shouldDisableStatusOptions =
    selectedInsight.actionInput !== ActionEnum.Accepted ||
    !selectedInsight.selectedDiagnosis ||
    !hasConditionStatusPermission

  return (
    <Container disableGutters>
      <RequiredLabel id='conditionStatus' text='Condition Status' />

      <RadioGroup>
        {conditionReasons.map((conditionReason: ConditionApprovalReason) => {
          const statusOptionId =
            'statusOption' + selectedInsight.id + conditionReason
          const radioOptionId = selectedInsight.id + conditionReason
          const treatmentId =
            'treatmentPlan' + selectedInsight.id + conditionReason

          const reasonDefaultNote = StatusTreatmentPlanMapping[conditionReason]

          const isStatusOptionSelected =
            selectedInsight.reasonInput === conditionReason

          return (
            <Accordion
              id={statusOptionId}
              data-testid={statusOptionId}
              disableGutters
              sx={getAccordionStyle(isStatusOptionSelected)}
              expanded={isStatusOptionSelected}
              key={statusOptionId}
            >
              <RadioOption
                optionId={radioOptionId}
                text={conditionReason}
                onChange={() => clickStatus(conditionReason, reasonDefaultNote)}
                isSelected={isStatusOptionSelected}
                isDisabled={shouldDisableStatusOptions}
                customStyle={radioOptionStyle}
              />
              <AccordionDetails>
                <CommonLabel text={'Describe treatment plan (Recommended)'} />
                <MoxeTextField
                  textFieldId={treatmentId}
                  text={selectedInsight.noteInput}
                  placeholderText={reasonDefaultNote}
                  onChange={(event) => handleNoteOnChange(event.target.value)}
                  onBlur={(event) => handleNoteOnBlur(event)}
                />
              </AccordionDetails>
            </Accordion>
          )
        })}
      </RadioGroup>
    </Container>
  )
}
