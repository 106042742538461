// Utils
import {getSessionId} from '../../utils/api/sessionId'
import {parseUrlQueryParams} from '../../utils/parseUrlQueryParams'

// Types
import {Options} from '../../types/api/requests/options'

const baseOptions = {
  'Content-Type': 'application/json',
  'x-moxe-session-id': getSessionId()
}

export const getAuthorizeOptions = (): Options => {
  const queryParams = parseUrlQueryParams(window.location.search)
  switch (window.location.pathname) {
    case '/v1/sonf/launch':
    case '/v1/sonf/app':
      return {
        method: 'get',
        timeout: process.env.REACT_APP_TIMEOUT
          ? +process.env.REACT_APP_TIMEOUT
          : 20000,
        headers: baseOptions
      }
    default:
      return {
        method: 'post',
        timeout: process.env.REACT_APP_TIMEOUT
          ? +process.env.REACT_APP_TIMEOUT
          : 20000, // copied from previous version of Convergence frontend
        // withCredentials: true, // is this needed? it works without it
        headers: baseOptions,
        body: JSON.stringify({
          username: queryParams.username,
          id: queryParams.patientId
        })
      }
  }
}

export const getOptionsWithAuthorizationHeader = (
  method: string,
  token: string
): Options => {
  return {
    method: method,
    headers: {
      ...baseOptions,
      Authorization: `Bearer ${token}`
    }
  }
}

export const getOptionsWithAuthorizationHeaderAndBody = (
  method: string,
  token: string,
  body: any
): Options => {
  let options = getOptionsWithAuthorizationHeader(method, token)
  options.body = JSON.stringify(body)
  return options
}
