// React
import {KeyboardEvent, useContext, useEffect, useState} from 'react'
import {Else, If, Then} from 'react-if'

// Material UI
import {Box, MenuItem, TextField, Autocomplete, Button} from '@mui/material'

// Font Awesome
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons'

// Context
import {SnackbarContext} from '../../../../context/SnackbarContext'
import {SelectedInsightContext} from '../../../../context/SelectedInsightContext'
import {ValidationContext} from '../../../../context/ValidationContext'

// Components
import {MoxeIcon} from '@moxe/component-library'
import {RequiredAsterisk} from '../../../reusable/RequiredAsterisk'

// Types
import {DiagnosisOption} from '../../../../types/api/responses/DiagnosisOption'
import {ActionEnum} from '../../../../types/state/ActionEnum'

// CSS
import {colors} from '../../../../css/colors'
import {fontWeights} from '../../../../css/fontWeights'
import {fontSizes} from '../../../../css/fontSizes'
import {listItemLabelStyle} from '../../../../css/styles/listItemLabelStyle'

// Utils
import {formatDiagnosisForDisplay} from '../../../../utils/formatDiagnosisForDisplay'
import {moxeFilter} from '../../../../utils/moxeFilter'

export const DiagnosisSelectionContent = () => {
  const {setSnackbarOptions} = useContext(SnackbarContext)
  const {selectedInsight, updateInsight} = useContext(SelectedInsightContext)
  const {shouldShowValidationStyling} = useContext(ValidationContext)

  const [autocompleteInputValue, setAutocompleteInputValue] =
    useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [highlightOption, setHighlightOption] = useState<DiagnosisOption>()

  const placeholderText: string = 'Search by diagnosis name or code'

  const clickUndo = () => {
    let selectedInsightCopy = {...selectedInsight}
    setSnackbarOptions({
      isSnackbarOpen: false,
      snackbarVariant: null,
      snackbarChildComponent: ''
    })
    if (selectedInsight.previousAction !== null) {
      // if the previous action wasn't null, reset previous inputs
      selectedInsightCopy.isEditMode = false
      selectedInsightCopy.actionInput = selectedInsight.previousAction
      selectedInsightCopy.selectedDiagnosis = selectedInsight.previousDiagnosis
      if (selectedInsight.previousAction === ActionEnum.Rejected) {
        selectedInsightCopy.reasonInput = selectedInsight.previousReason
        selectedInsightCopy.noteInput = selectedInsight.previousNote
      }
    } else {
      // if there isn't a previous action, reset everything to null, except edit mode depends on the recommended diagnosis
      selectedInsightCopy.isEditMode =
        selectedInsight.recommendedDiagnosis === null
      selectedInsightCopy.actionInput = null
      selectedInsightCopy.selectedDiagnosis = null
      selectedInsightCopy.reasonInput = null
      selectedInsightCopy.noteInput = null
    }
    selectedInsightCopy.previousAction = null
    selectedInsightCopy.previousDiagnosis = null
    selectedInsightCopy.previousReason = null
    selectedInsightCopy.previousNote = null
    updateInsight(selectedInsightCopy)
  }

  const undoButtonStyle = {
    display: 'contents',
    textTransform: 'none',
    padding: '0px',
    height: '16px',
    lineHeight: '16px',
    color: colors.orange[300],
    fontWeight: fontWeights.md
  }

  const diagnosisChangedToastComponent = (
    <Box>
      <Box sx={{display: 'inline-flex', marginRight: '16px'}}>
        Diagnosis code was changed.
      </Box>
      <Button
        variant='text'
        disableRipple
        disableFocusRipple
        onClick={clickUndo}
        sx={undoButtonStyle}
      >
        Undo
      </Button>
    </Box>
  )

  const selectDiagnosis = (newDiagnosis: DiagnosisOption) => {
    let selectedInsightCopy = {...selectedInsight}
    selectedInsightCopy.previousAction = selectedInsight.actionInput
    selectedInsightCopy.actionInput = ActionEnum.Accepted
    selectedInsightCopy.previousDiagnosis = selectedInsight.selectedDiagnosis
    selectedInsightCopy.selectedDiagnosis = newDiagnosis
    if (selectedInsight.actionInput === ActionEnum.Rejected) {
      selectedInsightCopy.previousReason = selectedInsight.reasonInput
      selectedInsightCopy.reasonInput = null
      selectedInsightCopy.previousNote = selectedInsight.noteInput
      selectedInsightCopy.noteInput = null
    }
    selectedInsightCopy.isEditMode = false
    updateInsight(selectedInsightCopy)
    setSnackbarOptions({
      isSnackbarOpen: true,
      snackbarVariant: 'default',
      snackbarChildComponent: diagnosisChangedToastComponent
    })
  }

  const selectDiagnosisAutoComplete = (event: any) => {
    let diagnosisCode = document
      .getElementById(event.target.id)
      ?.getAttribute('value')
    let renderValueText = placeholderText
    let selectedDiagnosis = selectedInsight.diagnoses.find(
      (diagnosis: DiagnosisOption) => diagnosis.formattedCode === diagnosisCode
    )
    if (selectedDiagnosis) {
      selectDiagnosis(selectedDiagnosis)
      renderValueText = formatDiagnosisForDisplay(selectedDiagnosis)
    }
    setAutocompleteInputValue(renderValueText)
    setIsOpen(false)
  }

  const handleAutocompleteKeyup = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setAutocompleteInputValue('')
      setIsOpen(true)
    }
    if (event.key === 'Enter' && highlightOption) {
      selectDiagnosis(highlightOption)
      setAutocompleteInputValue(formatDiagnosisForDisplay(highlightOption))
      setIsOpen(false)
    }
  }

  const labelStyle = {
    fontWeight: fontWeights.lg,
    marginTop: '16px',
    marginBottom: '8px'
  }

  const getAutocompleteStyle = () => {
    const isValid =
      selectedInsight.validation.isActionValid &&
      selectedInsight.validation.isSelectedDiagnosisValid
    const redBorder = colors.red[300]
    let shouldUseRedBorder = true
    if (isValid || (!isValid && !shouldShowValidationStyling)) {
      shouldUseRedBorder = false
    }
    return {
      width: '100%',
      backgroundColor: colors.gray[0],
      boxShadow:
        '0px 2px 4px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.02)',
      color: colors.gray[600],
      '&&& .MuiInputBase-input': {
        padding: '0',
        '& ::placeholder': {
          color: colors.gray[600]
        }
      },
      '.MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${shouldUseRedBorder ? redBorder : colors.gray[400]}`
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${shouldUseRedBorder ? redBorder : colors.gray[700]}`
      },
      '&&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${
          shouldUseRedBorder ? redBorder : colors.gray[1000]
        }`
      },
      '& input': {
        fontSize: fontSizes.md,
        '& ::placeholder': {
          color: colors.gray[600]
        }
      },
      '& input::placeholder': {
        color: colors.gray[600],
        opacity: '100%'
      }
    }
  }

  const getDiagnosisItemStyle = (diagnosis: DiagnosisOption) => {
    const selectedOrNotStyle =
      diagnosis.formattedCode ===
      selectedInsight.selectedDiagnosis?.formattedCode
        ? {
            backgroundColor: colors.orange[500],
            color: colors.gray[0],
            '&.Mui-focusVisible': {
              backgroundColor: colors.orange[500] + ' !important',
              border: `1px solid ${colors.orange[500]}`
            },
            '&:hover': {
              backgroundColor: colors.orange[500] + ' !important'
            }
          }
        : {
            '&.Mui-focusVisible': {
              backgroundColor: colors.gray[0],
              border: `1px solid ${colors.orange[500]}`
            },
            '&:hover': {
              backgroundColor: colors.orange[50] + ' !important'
            }
          }
    return {
      ...listItemLabelStyle,
      padding: '12px',
      minHeight: '40px',
      backgroundColor: colors.gray[0],
      whiteSpace: 'normal',
      ...selectedOrNotStyle
    }
  }

  useEffect(() => {
    if (!isOpen) {
      document
        .getElementById('diagnosisAutocomplete' + selectedInsight.id)
        ?.blur()
    }
  }, [isOpen, selectedInsight.id])

  const selectDiagnosisLabel = 'selectDiagnosisLabel' + selectedInsight.id

  return (
    <Box
      id={'diagnosisSelectionContent' + selectedInsight.id}
      data-testid={'diagnosisSelectionContent' + selectedInsight.id}
      sx={{padding: '0 12px 12px 12px'}}
    >
      <Box
        id={selectDiagnosisLabel}
        data-testid={selectDiagnosisLabel}
        sx={labelStyle}
      >
        <If condition={selectedInsight.selectedDiagnosis !== null}>
          <Then>Change diagnosis code</Then>
          <Else>
            Select a diagnosis <RequiredAsterisk />
          </Else>
        </If>
      </Box>
      <Autocomplete
        id={'diagnosisAutocomplete' + selectedInsight.id}
        data-testid={'diagnosisAutocomplete' + selectedInsight.id}
        sx={getAutocompleteStyle()}
        fullWidth
        open={isOpen}
        clearOnEscape={true}
        filterOptions={() => {
          return moxeFilter(autocompleteInputValue, selectedInsight.diagnoses)
        }}
        onFocus={(e) => {
          setIsOpen(true)
        }}
        onBlur={(e) => {
          setIsOpen(false)
        }}
        renderInput={(params) => (
          <Box
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <TextField
              {...params}
              label=''
              sx={{
                '.MuiAutocomplete-inputRoot': {
                  paddingRight: '15px !important'
                },
                '& .MuiAutocomplete-inputRoot:hover': {
                  cursor: 'pointer'
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Box
                    onClick={() => {
                      setIsOpen(!isOpen)
                    }}
                  >
                    <MoxeIcon
                      icon={faChevronDown}
                      color={colors.gray[1000]}
                      fontSize={fontSizes.sm}
                    />
                  </Box>
                )
              }}
              value={autocompleteInputValue}
              placeholder={placeholderText}
              onChange={(e) => {
                setAutocompleteInputValue(e.target.value)
                setIsOpen(true)
              }}
            />
          </Box>
        )}
        options={selectedInsight.diagnoses}
        getOptionLabel={(diagnosis) => {
          return formatDiagnosisForDisplay(diagnosis)
        }}
        onHighlightChange={(event, option) => {
          if (option) {
            setHighlightOption(option)
          }
        }}
        onKeyUp={(event) => {
          handleAutocompleteKeyup(event)
        }}
        renderOption={(props, diagnosis) => (
          <MenuItem
            {...props}
            id={'diagnosisOption' + diagnosis.code}
            data-testid={'diagnosisOption' + diagnosis.code}
            value={diagnosis.formattedCode}
            disableRipple
            sx={getDiagnosisItemStyle(diagnosis)}
            key={diagnosis.formattedCode}
            onClick={(e: any) => {
              selectDiagnosisAutoComplete(e)
            }}
          >
            {formatDiagnosisForDisplay(diagnosis)}
          </MenuItem>
        )}
        inputValue={autocompleteInputValue}
      ></Autocomplete>
    </Box>
  )
}
