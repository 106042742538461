// Material UI
import {Box, Skeleton} from '@mui/material'

// Font Awesome
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons'

// Components
import {MoxeIcon} from '@moxe/component-library'

// CSS
import {colors} from '../../css/colors'
import {fontSizes} from '../../css/fontSizes'

export const InsightsLoading = () => {
  const categoryHeaderBoxStyle = {
    display: 'inline-flex',
    marginBottom: '16px'
  }

  const chevronDownBoxStyle = {
    display: 'inline-flex',
    margin: '3px 0px'
  }

  const categoryHeaderSkeletonStyle = {
    display: 'inline-flex',
    width: '150px',
    height: '16px',
    margin: '1px 0px 1px 6px',
    borderRadius: '4px',
    backgroundColor: '#F0F0F0'
  }

  const categoryCountBoxStyle = {
    width: '18px',
    height: '18px',
    marginLeft: '4px',
    borderRadius: '3px',
    backgroundColor: colors.gray[100]
  }

  const categoryCountSkeletonStyle = {
    width: '10px',
    height: '10px',
    margin: '4px',
    borderRadius: '2px',
    backgroundColor: '#DFDFDF'
  }

  const insightItemSkeletonStyle = {
    height: '40px',
    marginBottom: '4px',
    borderRadius: '4px',
    backgroundColor: '#F0F0F0'
  }

  return (
    <Box id='insightsLoading' data-testid='insightsLoading'>
      <Box sx={categoryHeaderBoxStyle}>
        <Box sx={chevronDownBoxStyle}>
          <MoxeIcon
            icon={faChevronDown}
            color={colors.gray[800]}
            fontSize={fontSizes.sm}
          />
        </Box>
        <Skeleton
          animation='wave'
          variant='rectangular'
          sx={categoryHeaderSkeletonStyle}
        />
        <Box sx={categoryCountBoxStyle}>
          <Skeleton
            animation='wave'
            variant='rectangular'
            sx={categoryCountSkeletonStyle}
          />
        </Box>
      </Box>

      {[...Array(5)].map((_, index) => (
        <Skeleton
          animation='wave'
          variant='rectangular'
          sx={insightItemSkeletonStyle}
          key={'insightsLoadingSkeleton' + index}
        />
      ))}
    </Box>
  )
}
