// React
import {useContext} from 'react'
import {If, Then} from 'react-if'

// Context
import {ConfigurationContext} from '../../../../context/ConfigurationContext'

// Material UI
import {Container, Box} from '@mui/material'

// Components
import {DiagnosisSelectionOption} from './DiagnosisSelectionOption'
import {RejectDiagnosisOption} from './RejectDiagnosisOption'
import {StatusForm} from './StatusForm'

// CSS
import {fontWeights} from '../../../../css/fontWeights'

export const DiagnosisForm = () => {
  const labelStyle = {
    fontWeight: fontWeights.lg,
    marginTop: '16px',
    marginBottom: '8px'
  }
  const {userRole} = useContext(ConfigurationContext)

  return (
    <Container id='diagnosisForm' data-testid='diagnosisForm' disableGutters>
      <Box id='labelDiagnosis' data-testid='labelDiagnosis' sx={labelStyle}>
        Diagnosis
      </Box>
      <DiagnosisSelectionOption />
      <RejectDiagnosisOption />
      <If condition={userRole === 'Clinician'}>
        <Then>
          <StatusForm />
        </Then>
      </If>
    </Container>
  )
}
