export const constants = {
  LOCAL: 'local',
  TEST: 'test',
  INT: 'int',
  DEMO: 'demo',
  PROD: 'prod',

  HEALTH_CONDITION_CONFIRMATION: 'Health Condition Confirmation',

  QUALITY_REJECTION_STATUS_LABEL: 'Not needed'
}
