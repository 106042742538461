// Font Awesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

// Types
import {MoxeIconProps} from '../../types/props/MoxeIconProps'

export const MoxeIcon = (props: MoxeIconProps) => {
  const {iconId, icon, color, fontSize, classes} = props

  const id = 'moxeIcon' + iconId

  return (
    <FontAwesomeIcon
      id={id}
      data-testid={id}
      icon={icon}
      color={color}
      style={{fontSize, width: fontSize}}
      className={classes}
    />
  )
}
