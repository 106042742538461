// React
import {useContext, useState} from 'react'
import {Else, If, Then} from 'react-if'

// Material UI
import {Box, Typography} from '@mui/material'

// Components
import {InsightFormHeader} from './InsightFormHeader'
import {DiagnosisForm} from './diagnosisForm/DiagnosisForm'
import {ActionForm} from './actionForm/ActionForm'
import {ValidationError} from './ValidationError'
import {MoxeButton} from '../../reusable/MoxeButton'

// Context
import {AppContext} from '../../../context/AppContext'
import {ConfigurationContext} from '../../../context/ConfigurationContext'
import {SelectedInsightContext} from '../../../context/SelectedInsightContext'
import {SubmissionScreenContext} from '../../../context/SubmissionScreenContext'
import {SnackbarContext} from '../../../context/SnackbarContext'
import {ValidationContext} from '../../../context/ValidationContext'

// Utils
import {moxeFetch} from '../../../utils/api/moxeFetch'
import {formatDiagnosisForDisplay} from '../../../utils/formatDiagnosisForDisplay'
import {validateInsight} from '../../../utils/validation'
import {isInsightIncomplete} from '../../../utils/isInsightIncomplete'
import {determineInsightSavedStatus} from '../../../utils/determineInsightSavedStatus'
import {hasPermission} from '../../../utils/hasPermission'

// Constants
import {getConvergenceApiHost} from '../../../constants/api/apiHosts'
import {getInsightFormSubmissionEndpoint} from '../../../constants/api/apiEndpoints'
import {getOptionsWithAuthorizationHeaderAndBody} from '../../../constants/api/apiOptions'

// Types
import {UpdateInsightResponse} from '../../../types/api/responses/UpdateInsightResponse'
import {InsightStatusEnum} from '../../../types/state/InsightStatusEnum'
import {MoxeModalContext} from '../../../context/MoxeModalContext'

export const InsightForm = () => {
  const {token, insights} = useContext(AppContext)
  const {userPermissions} = useContext(ConfigurationContext)
  const {selectedInsight, updateSelectedInsight, updateInsight} = useContext(
    SelectedInsightContext
  )
  const {setShouldBeOnSubmissionScreen} = useContext(SubmissionScreenContext)
  const {setSnackbarOptions} = useContext(SnackbarContext)
  const {setModalContent, setIsModalOpen} = useContext(MoxeModalContext)
  const [shouldShowValidationStyling, setShouldShowValidationStyling] =
    useState<boolean>(false)

  if (selectedInsight === undefined) {
    return (
      <Typography
        id='noInsightSelectedText'
        data-testid='noInsightSelectedText'
        variant='h3'
        sx={{marginTop: '5px'}}
      >
        No insight selected
      </Typography>
    )
  }

  const handleError = () => {
    setModalContent(
      <Box>
        <p>Failed to save work.</p>
        <p>Please exit the screen and try again.</p>
      </Box>
    )
    setIsModalOpen(true)
  }

  const clickSaveAndContinue = () => {
    let selectedInsightCopy = {...selectedInsight}
    let selectedInsightValidation = validateInsight(selectedInsightCopy)
    selectedInsightCopy.validation = selectedInsightValidation
    if (
      selectedInsightValidation.isActionValid &&
      selectedInsightValidation.isSelectedDiagnosisValid
    ) {
      // if both action and diagnosis are valid, make api call
      let body = {
        action: selectedInsight.actionInput,
        insightType: selectedInsight.category,
        statusNote: {
          value: selectedInsight.reasonInput,
          noteText: selectedInsight.noteInput
        },
        insightId: selectedInsight.id,
        diagnosis: {
          value: selectedInsight.selectedDiagnosis?.formattedCode,
          defaultText: selectedInsight.selectedDiagnosis
            ? formatDiagnosisForDisplay(selectedInsight.selectedDiagnosis)
            : null
        }
      }
      moxeFetch<any>(
        getConvergenceApiHost() + getInsightFormSubmissionEndpoint(),
        getOptionsWithAuthorizationHeaderAndBody('put', token, body)
      )
        .then((response: UpdateInsightResponse) => {
          // update the insight that we just updated on the backend, using the data returned from the backend
          selectedInsightCopy.actionInput = response.providerAction
          selectedInsightCopy.reasonInput = response.reason
          selectedInsightCopy.noteInput = response.note
          selectedInsightCopy.selectedDiagnosis = response.selectedDiagnosis
          selectedInsightCopy.actionSaved = response.providerAction
          selectedInsightCopy.reasonSaved = response.reason
          selectedInsightCopy.noteSaved = response.note
          selectedInsightCopy.selectedDiagnosisSaved =
            response.selectedDiagnosis
          selectedInsightCopy.isConfirmed = response.isConfirmed
          selectedInsightCopy.status =
            determineInsightSavedStatus(selectedInsightCopy)
          updateInsight(selectedInsightCopy)
          showSnackbar(selectedInsightCopy.name + ' saved.')
          moveToNextInsight()
        })
        .catch((error) => {
          handleError()
        })
    } else {
      updateInsight(selectedInsightCopy)
      if (
        !selectedInsightValidation.isActionValid &&
        !selectedInsightValidation.isSelectedDiagnosisValid &&
        hasPermission(userPermissions, 'SAVE_AND_CONTINUE_WITHOUT_INPUT')
      ) {
        // if both action and diagnosis aren't valid and user has permission to save without any input, don't make api call, don't activate validation, and skip
        clickSkip()
      } else {
        // if one of either action or diagnosis aren't valid, or both are invalid and the user doesn't have permission to save without any input, don't make api call, activate validation
        setShouldShowValidationStyling(true)
        setTimeout(() => setShouldShowValidationStyling(false), 2000)
      }
    }
  }

  const clickSkip = () => {
    if (isInsightIncomplete(selectedInsight)) {
      let selectedInsightCopy = {...selectedInsight}
      selectedInsightCopy.status = InsightStatusEnum.Incomplete
      updateInsight(selectedInsightCopy)
    }
    showSnackbar(selectedInsight.name + ' was skipped.')
    moveToNextInsight()
  }

  const showSnackbar = (message: string) => {
    setSnackbarOptions({
      isSnackbarOpen: true,
      snackbarVariant: 'default',
      snackbarChildComponent: message
    })
  }

  const moveToNextInsight = () => {
    // update the selected insight to be the next insight in the list so that the app navigates to next
    const newSelectedInsight = insights.find(
      (insight) => insight.order === selectedInsight.order + 1
    )
    if (newSelectedInsight) {
      updateSelectedInsight(newSelectedInsight)
    } else {
      setShouldBeOnSubmissionScreen(true)
    }
  }

  const validationContext = {
    shouldShowValidationStyling
  }

  return (
    <Box id='formPane' data-testid='formPane'>
      <InsightFormHeader />
      <ValidationContext.Provider value={validationContext}>
        <Box sx={{marginBottom: '20px'}}>
          <If condition={selectedInsight.category !== 'Quality Measures'}>
            <Then>
              <DiagnosisForm />
            </Then>
            <Else>
              <ActionForm />
            </Else>
          </If>
        </Box>
      </ValidationContext.Provider>
      <If
        condition={
          !hasPermission(userPermissions, 'SAVE_AND_CONTINUE_WITHOUT_INPUT') &&
          (!selectedInsight.validation.isActionValid ||
            !selectedInsight.validation.isSelectedDiagnosisValid)
        }
      >
        <Then>
          <ValidationError />
        </Then>
      </If>

      <Box sx={{display: 'inline-block', marginRight: '16px'}}>
        <MoxeButton
          buttonId={'SaveAndContinue' + selectedInsight.id}
          text='Save and Continue'
          moxeVariant='primary'
          height='32px'
          onClick={() => clickSaveAndContinue()}
        />
      </Box>
      <MoxeButton
        buttonId={'Skip' + selectedInsight.id}
        text='Skip'
        moxeVariant='secondary'
        height='32px'
        onClick={() => clickSkip()}
      />
    </Box>
  )
}
