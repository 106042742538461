// Components
import {MoxeIcon} from '@moxe/component-library'

// Material UI
import {Box} from '@mui/material'

// Font Awesome
import {faCheck} from '@fortawesome/pro-regular-svg-icons'

// CSS
import {fontWeights} from '../../../css/fontWeights'
import {colors} from '../../../css/colors'
import {fontSizes} from '../../../css/fontSizes'

export const SuccessModalContent = () => {
  const contentStyle = {
    padding: '16px',
    textAlign: 'center',
    color: colors.gray[900]
  }

  const successLabelStyle = {
    margin: '16px 0px 12px 0px',
    color: colors.green[500],
    fontWeight: fontWeights.xl,
    fontSize: fontSizes.xl
  }

  return (
    <Box
      id='successModalContent'
      data-testid='successModalContent'
      sx={contentStyle}
    >
      <MoxeIcon icon={faCheck} color={colors.green[500]} fontSize='32px' />
      <Box sx={successLabelStyle}>Success!</Box>
      <Box>Thank you - your work has been submitted.</Box>
      <Box>You can close this window safely.</Box>
    </Box>
  )
}
