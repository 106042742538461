import React from 'react'
import {AppContextState} from '../types/state/AppContextState'

export const AppContext = React.createContext<AppContextState>({
  insights: [],
  setInsights: () => {},
  token: '',
  physician: {
    id: '',
    idType: '',
    npi: '',
    name: '',
    department: '',
    location: ''
  },
  setPhysician: () => {},
  isLoadingCriticalAppData: true,
  isLoadingConfiguration: true
})
