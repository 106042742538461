import {Insight} from '../types/state/Insight'
import {InsightCategoryEnum} from '../types/state/InsightCategoryEnum'
import {InsightStatusEnum} from '../types/state/InsightStatusEnum'

export const determineInsightSavedStatus = (insight: Insight) => {
  const someSavedDataExists =
    insight.actionSaved !== null ||
    insight.selectedDiagnosisSaved !== null ||
    insight.reasonSaved ||
    insight.noteSaved
  if (!someSavedDataExists) {
    return InsightStatusEnum.NotAddressed
  } else if (insight.isConfirmed) {
    const statusKey = insight.actionSaved as keyof typeof InsightStatusEnum
    if (
      insight.category !== InsightCategoryEnum.QualityMeasures &&
      statusKey === 'Accepted' &&
      (insight.selectedDiagnosisSaved === null || !insight.reasonSaved)
    ) {
      return InsightStatusEnum.Incomplete
    }
    return InsightStatusEnum[statusKey]
  } else {
    return InsightStatusEnum.Incomplete
  }
}
