import {colors} from '../colors'

export const leftPaneDropdownHeaderStyle = {
  display: 'inline-flex',
  marginBottom: '16px',
  padding: '0px',
  borderRadius: '2px',
  '&:hover, &:focus': {
    backgroundColor: colors.gray[50]
  },
  '&:focus': {
    border: `1px solid ${colors.orange[500]}`
  }
}
