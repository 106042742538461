// React
import {ReactElement, useEffect, useState} from 'react'
import {If, Then} from 'react-if'

// Material UI
import {Box, Container, Modal} from '@mui/material'

// Font Awesome
import {faTimes} from '@fortawesome/pro-regular-svg-icons'

// Components
import {AppContent} from './components/AppContent'
import {MoxeSnackbar} from './MoxeSnackbar'
import {MoxeErrorModalContent} from './MoxeErrorModalContent'
import {MoxeIcon} from '@moxe/component-library'

// Utils
import {moxeFetch} from './utils/api/moxeFetch'
import {defaultInsightValidation} from './utils/validation'
import {parseJwt} from './utils/parseJwt'
import {determineInsightSavedStatus} from './utils/determineInsightSavedStatus'

// Types
import {TokenPatientResponse} from './types/api/responses/TokenPatientResponse'
import {InsightResponse} from './types/api/responses/InsightResponse'
import {Insight} from './types/state/Insight'
import {SnackbarOptions} from './types/state/SnackbarOptions'
import {Physician} from './types/api/responses/Physician'
import {ConfigurationResponse} from './types/api/responses/ConfigurationResponse'

// CSS
import {colors} from './css/colors'

// Context
import {AppContext} from './context/AppContext'
import {SnackbarContext} from './context/SnackbarContext'
import {MoxeModalContext} from './context/MoxeModalContext'
import {ConfigurationContext} from './context/ConfigurationContext'

// Constants
import {getConvergenceApiHost} from './constants/api/apiHosts'
import {
  getAuthorizeEndpoint,
  getConfigurationEndpoint,
  getInsightsEndpoint
} from './constants/api/apiEndpoints'
import {
  getAuthorizeOptions,
  getOptionsWithAuthorizationHeader
} from './constants/api/apiOptions'
import {InsightStatusEnum} from './types/state/InsightStatusEnum'
import {StatusGroupEnum} from './types/state/StatusGroupEnum'

const App = () => {
  const [token, setToken] = useState<string>('')
  const [physician, setPhysician] = useState<Physician>({
    id: '',
    idType: '',
    npi: '',
    name: '',
    department: '',
    location: ''
  })

  const [isLoadingInsights, setIsLoadingInsights] = useState<boolean>(true)
  const [isLoadingConfiguration, setIsLoadingConfiguration] =
    useState<boolean>(true)

  const [insights, setInsights] = useState<Insight[]>([])
  const [configurationData, setConfigurationData] =
    useState<ConfigurationResponse>({
      rejectionReasons: [],
      userRole: 'NonClinician',
      userPermissions: []
    })

  const [isAppError, setIsAppError] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<ReactElement>(<></>)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [snackbarOptions, setSnackbarOptions] = useState<SnackbarOptions>({
    isSnackbarOpen: false,
    snackbarVariant: null,
    snackbarChildComponent: ''
  })

  const convergenceHost = getConvergenceApiHost()
  const optionsWithAuthHeader = getOptionsWithAuthorizationHeader('get', token)

  useEffect(() => {
    if (window.location.pathname === '/') {
      var token = window.location.search.slice(1).split('&')[0].split('=')[1]
      setToken(token)
      const parsedJwt = parseJwt(token)
      const physician: Physician = {
        id: parsedJwt.m_prov_id,
        idType: parsedJwt.m_prov_id_t,
        npi: parsedJwt.m_npi,
        name: parsedJwt.m_user_display_name,
        department: parsedJwt.m_edi,
        location: parsedJwt.m_loi
      }
      setPhysician(physician)
    } else {
      moxeFetch<TokenPatientResponse>(
        convergenceHost + getAuthorizeEndpoint(),
        getAuthorizeOptions()
      )
        .then((result) => {
          setToken(result.token)
          let parsedJwt = parseJwt(result.token)
          let physician: Physician = {
            id: parsedJwt.m_prov_id,
            idType: parsedJwt.m_prov_id_t,
            npi: parsedJwt.m_npi,
            name: parsedJwt.m_user_display_name,
            department: parsedJwt.m_edi,
            location: parsedJwt.m_loi
          }
          setPhysician(physician)
        })
        .catch((error: Error) => {
          handleCatch()
        })
    }
  }, [convergenceHost])

  useEffect(() => {
    if (token !== '') {
      moxeFetch<InsightResponse[]>(
        convergenceHost + getInsightsEndpoint(),
        optionsWithAuthHeader
      )
        .then((responseInsights) => {
          responseInsights.sort((a, b) => a.order - b.order)
          const insights: Insight[] = responseInsights.map(
            (responseInsight) => {
              const insight = {
                id: responseInsight.id,
                name: responseInsight.name,
                category: responseInsight.category,
                order: responseInsight.order,
                supportingEvidence: responseInsight.supportingEvidence,
                diagnoses: responseInsight.diagnoses,
                recommendedDiagnosis: responseInsight.recommendedDiagnosis,
                isEditMode:
                  !responseInsight.selectedDiagnosis &&
                  !responseInsight.recommendedDiagnosis,
                validation: defaultInsightValidation(),
                previousAction: null,
                previousDiagnosis: null,
                previousReason: null,
                previousNote: null,
                actionInput: responseInsight.action,
                reasonInput: responseInsight.reason,
                noteInput: responseInsight.note,
                selectedDiagnosis: responseInsight.selectedDiagnosis
                  ? responseInsight.selectedDiagnosis
                  : null,
                // set it as NotAddressed temporarily because we're going to use the full insight object to determine the actual status right after this
                status: InsightStatusEnum.NotAddressed,
                summaryScreenStatus: StatusGroupEnum.NotAddressed,
                actionSaved: responseInsight.action,
                reasonSaved: responseInsight.reason,
                noteSaved: responseInsight.note,
                selectedDiagnosisSaved: responseInsight.selectedDiagnosis
                  ? responseInsight.selectedDiagnosis
                  : null,
                isConfirmed: responseInsight.isConfirmed
              }
              insight.status = determineInsightSavedStatus(insight)
              return insight
            }
          )
          setInsights(insights)
        })
        .catch((error) => {
          handleCatch()
        })
        .finally(() => {
          setIsLoadingInsights(false)
        })
    }
  }, [convergenceHost, token])

  useEffect(() => {
    if (token !== '') {
      moxeFetch<ConfigurationResponse>(
        convergenceHost + getConfigurationEndpoint(),
        optionsWithAuthHeader
      )
        .then((configResponse) => {
          setConfigurationData(configResponse)
        })
        .catch(() => {
          handleCatch()
        })
        .finally(() => {
          setIsLoadingConfiguration(false)
        })
    }
  }, [convergenceHost, token])

  const handleCatch = () => {
    setIsAppError(true)
    setModalContent(
      <Box>
        <p>Failed to load conditions.</p>
        <p>Please exit the screen and try again.</p>
      </Box>
    )
    setIsModalOpen(true)
  }

  const xContainerStyling = {
    position: 'absolute',
    top: '8px',
    right: '11px',
    ':hover': {
      cursor: 'pointer'
    }
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '451px',
    color: colors.gray[1000],
    background: colors.gray[0],
    borderRadius: '4px',
    boxShadow:
      '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)'
  }

  const appContext = {
    insights,
    setInsights,
    token,
    physician,
    setPhysician,
    isLoadingCriticalAppData: isLoadingInsights || isLoadingConfiguration,
    isLoadingConfiguration: isLoadingConfiguration
  }

  const snackbarContext = {
    snackbarOptions,
    setSnackbarOptions
  }

  const modalContext = {
    setIsModalOpen,
    setModalContent,
    modalContent,
    isModalOpen
  }

  const configurationContext = {
    rejectionReasons: configurationData.rejectionReasons,
    userRole: configurationData.userRole,
    userPermissions: configurationData.userPermissions
  }

  return (
    <Container
      id='appContainer'
      data-testid='appContainer'
      disableGutters
      maxWidth={false}
    >
      <MoxeModalContext.Provider value={modalContext}>
        <SnackbarContext.Provider value={snackbarContext}>
          <If condition={!isAppError}>
            <Then>
              <AppContext.Provider value={appContext}>
                <ConfigurationContext.Provider value={configurationContext}>
                  <AppContent />
                </ConfigurationContext.Provider>
              </AppContext.Provider>
            </Then>
          </If>
          <MoxeSnackbar />
        </SnackbarContext.Provider>
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Box sx={modalStyle}>
            <Box onClick={() => setIsModalOpen(false)} sx={xContainerStyling}>
              <MoxeIcon
                icon={faTimes}
                color={colors.gray[600]}
                fontSize='16px'
              />
            </Box>
            <MoxeErrorModalContent />
          </Box>
        </Modal>
      </MoxeModalContext.Provider>
    </Container>
  )
}

export default App
