// React
import {useContext} from 'react'
import {Else, If, Then} from 'react-if'

// Material UI
import {Box} from '@mui/material'

// Context
import {StatusSectionContext} from '../../../context/StatusSectionContext'

// Types
import {InsightCategoryEnum} from '../../../types/state/InsightCategoryEnum'

// CSS
import {colors} from '../../../css/colors'
import {
  insightContainerStyle,
  insightHeaderStyle,
  insightNameStyle,
  noteContainerStyle,
  noteLabelStyle
} from '../../../css/styles/summaryInsightStyle'

export const IncompleteStatusSectionContent = () => {
  const {statusGroup, areNotesDisplayed} = useContext(StatusSectionContext)

  const insightHeaderNotAsContainerStyle = {
    ...insightHeaderStyle,
    borderRadius: areNotesDisplayed ? '4px 4px 0px 0px' : '4px 4px 4px 4px',
    borderBottom: areNotesDisplayed ? `1px solid ${colors.gray[300]}` : 'none'
  }

  return (
    <Box id='incompleteStatusSection' data-testid='incompleteStatusSection'>
      {statusGroup.insights.map((insight) => {
        const baseId = 'incompleteStatusSection'
        const insightContainerId = baseId + 'InsightContainer' + insight.name
        const insightNameId = baseId + 'InsightName' + insight.name
        const insightNoteContainerId =
          baseId + 'InsightNoteContainer' + insight.name
        const insightNoteId = baseId + 'InsightNote' + insight.name
        return (
          <Box
            id={insightContainerId}
            data-testid={insightContainerId}
            sx={insightContainerStyle}
            key={insight.id}
          >
            <If
              condition={
                insight.category !== InsightCategoryEnum.QualityMeasures
              }
            >
              <Then>
                <Box
                  sx={{
                    ...insightHeaderNotAsContainerStyle,
                    borderBottom: areNotesDisplayed
                      ? `1px solid ${colors.gray[300]}`
                      : 'none'
                  }}
                >
                  <Box
                    id={insightNameId}
                    data-testid={insightNameId}
                    sx={insightNameStyle}
                  >
                    {insight.name}
                  </Box>
                </Box>
              </Then>
              <Else>
                <Box
                  sx={
                    areNotesDisplayed && insight.noteSaved
                      ? insightHeaderNotAsContainerStyle
                      : insightHeaderStyle
                  }
                >
                  <Box
                    id={insightNameId}
                    data-testid={insightNameId}
                    sx={insightNameStyle}
                  >
                    {insight.name}
                  </Box>
                </Box>
              </Else>
            </If>
            <If condition={areNotesDisplayed}>
              <Then>
                <Box
                  id={insightNoteContainerId}
                  data-testid={insightNoteContainerId}
                  sx={noteContainerStyle}
                >
                  <Box sx={noteLabelStyle}>Incomplete:</Box>
                  <Box
                    id={insightNoteId}
                    data-testid={insightNoteId}
                    sx={{color: colors.gray[700]}}
                  >
                    No condition status selected
                  </Box>
                </Box>
              </Then>
            </If>
          </Box>
        )
      })}
    </Box>
  )
}
