import {constants} from '../constants'

export const getConvergenceApiHost = (): string => {
  switch (process.env.REACT_APP_ENV) {
    case constants.LOCAL:
      return 'http://localhost:8080'
    case constants.TEST:
    case constants.INT:
    case constants.DEMO:
      return `https://${process.env.REACT_APP_ENV}-convergenceapi.moxehealth.com`
    case constants.PROD:
      return 'https://convergenceapi.moxehealth.com'
    default:
      return 'https://test-convergenceapi.moxehealth.com'
  }
}
