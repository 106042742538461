type queryParams = {
  username: string
  patientId: string
  [key: string]: string
}

export const parseUrlQueryParams = (urlSearch: string): queryParams => {
  const queryParams: any = {}

  urlSearch
    .replace(/^\?/, '')
    .split('&')
    .forEach((pair) => {
      let [key, value] = pair.split('=')
      queryParams[key] = value
    })

  return queryParams
}
