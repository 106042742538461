import {MoxeIcon} from '@moxe/component-library'
import {colors} from '@moxe/design-tokens'
import {Box} from '@mui/material'
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons'
import {fontSizes} from '../../../css/fontSizes'

export const EncounterDetailsError = () => {
  const encounterDetailsErrorContainerStyle = {
    padding: '6px 0px',
    textAlign: 'center',
    marginBottom: '12px'
  }

  const errorTextStyle = {
    marginTop: '8.5px',
    color: colors.gray[900]
  }

  const encounterDetailsErrorId = 'encounterDetailsError'

  return (
    <Box
      id={encounterDetailsErrorId}
      data-testid={encounterDetailsErrorId}
      sx={encounterDetailsErrorContainerStyle}
    >
      <MoxeIcon
        icon={faExclamationTriangle}
        color={colors.red[500]}
        fontSize={fontSizes.xl}
      />
      <Box sx={errorTextStyle}>
        Unable to load the patient details and comment.
      </Box>
    </Box>
  )
}
