import {MoxeApiResponse} from '../../types/api/responses/MoxeApiResponse'
import {CustomError} from './CustomError'

export const moxeFetch = <T>(url: string, options?: {}): Promise<T> => {
  return fetch(url, options)
    .then(async (res) => {
      if (!res.ok) {
        await (res.json() as Promise<MoxeApiResponse<T>>).then((json) => {
          throw new CustomError(json.error)
        })
      }
      return res.json() as Promise<MoxeApiResponse<T>>
    })
    .then((json) => json.data)
}
