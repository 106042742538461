import {ActionEnum} from '../types/state/ActionEnum'
import {Insight} from '../types/state/Insight'
import {InsightCategoryEnum} from '../types/state/InsightCategoryEnum'
import {InsightValidation} from '../types/state/InsightValidation'

export const defaultInsightValidation = (): InsightValidation => {
  return {
    isActionValid: true,
    isSelectedDiagnosisValid: true
  }
}

export const validateInsight = (insight: Insight): InsightValidation => {
  return {
    isActionValid: insight.actionInput !== null,
    isSelectedDiagnosisValid:
      insight.category !== InsightCategoryEnum.QualityMeasures &&
      insight.actionInput !== ActionEnum.Rejected
        ? insight.selectedDiagnosis !== null
        : true
  }
}
