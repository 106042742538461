const base = {
  orange: {
    50: '#FFF8F5',
    200: '#FFE6DB',
    300: '#FFB899',
    400: '#FF7233',
    500: '#FF4F00',
    600: '#D64200'
  },
  gray: {
    0: '#FFFFFF',
    50: '#FAFAFA',
    100: '#F4F5F5',
    200: '#ECEDEE',
    300: '#E4E5E7',
    400: '#D6D8DB',
    500: '#BBBFC3',
    600: '#93989F',
    700: '#6D7278',
    800: '#53565A',
    900: '#27292A',
    1000: '#000000'
  },
  red: {
    50: '#FEF5F5',
    100: '#FDE8E9',
    200: '#F9C7C8',
    300: '#F38E92',
    400: '#EB4B51',
    500: '#E61E25',
    600: '#D11B22'
  },
  green: {
    50: '#F3FBF5',
    200: '#C7EBCF',
    500: '#48BD62'
  },
  yellow: {
    50: '#FFFCF5',
    200: '#FFE29D',
    500: '#FEBE2A'
  }
}

export const colors = {
  ...base
}
